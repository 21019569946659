import React, { useState, useEffect } from 'react';
import { Modal, Button, Upload, message, notification, Form, Input, Select } from 'antd';
import axios from 'axios';
import { CloseCircleOutlined, UploadOutlined } from '@ant-design/icons';
import noImage from "./img/noimage.jpg";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const EditCategoryModal = ({
  category,
  show,
  onCancel,onHide,
  onSave,
  isLoadingEdit,
  editChitDetails,
  fetchData
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const token = localStorage.getItem('token');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]); // Add stateList state variable
  const [selectedState, setSelectedState] = useState(null); // Add selectedState state variable
  const [pincodeList, setPincodeList] = useState([]);
  const [nativee, setNative] = useState("");
  const [kovil, setKovil] = useState("");
  const [perivu, setPerivu] = useState([]);
  const [selectedPerivu, setSelectedPerivu] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [spousenativee, setNativeforSpouse] = useState("");
  const [selectedPerivuforSpouse, setSelectedPerivuforSpouse] = useState("");
  const [spouseperivu, setspouseperivu] = useState([]);
  const [fathersnativee, setfathersnativee] = useState("");
  const [fatherskovil, setfatherskovil] = useState("");
  const [fathersperivu, setfathersperivu] = useState([]);
  const [selectedPerivuforFather, setSelectedPerivuforFather] = useState("");
  const [data, setData] = useState([]);
  const [companycategory, setCompanycategory] = useState("");
  const [companymobile, setMobileforCompany] = useState("");
  const [companyemail, setEmailforComapny] = useState("");
  const [isValidEmailforCompany, setIsValidEmailforCompany] = useState(true);
  const [selectedCountryforCompany, setSelectedCountryforCompany] = useState(null);
  const [selectedStateforCompany, setSelectedStateforCompany] = useState(null); // Add selectedState state variable
  const [countryListforCompany, setCountryListforCompany] = useState([]);
  const [stateListforCompany, setStateListforCompany] = useState([]); // Add stateList state variable
  const [pincodeListforCompany, setPincodeListforCompany] = useState([]);
  const [memberTypes, setMemberTypes] = useState([]);
  const [selectedMemberType, setSelectedMemberType] = useState([]);
  const [fileList, setFileList] = useState([]); // To store uploaded files
  const [profilepic, setProfilepic] = useState(category.profilepic); 
  const [selectedReferral, setSelectedReferral] = useState(null);
  const [referedDetails, setReferedDetails] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      clientid: category.clientid,
      idno: category.idno,
      firstname: category.firstname,
      gender: category.gender,
      maritalstatus: category.maritalstatus,
      address1: category.address1,
      area: category.area,
      city: category.city,
      nativee: category.nativee,
      kovil: category.kovil,
      perivu: category.perivu,
      mobile: category.mobile,
      proposername: category.proposername,
      secordorname: category.secordorname,
      yearofjoining: category.yearofjoining,
      spousename: category.spousename,
      spousenativee: category.spousenativee,
      spousekovil: category.spousekovil,
      spouseperivu: category.spouseperivu,
      fathersname: category.fathersname,
      fathersnativee: category.fathersnativee,
      fatherskovil: category.fatherskovil,
      fathersperivu: category.fathersperivu,
      companyname: category.companyname,
      companycategory: category.companycategory,
      companylandline: category.companylandline,
      companymobile: category.companymobile,
      companyemail: category.companyemail,
      companywebsite: category.companywebsite,
      companyaddress1: category.companyaddress1,
      companyarea: category.companyarea,
      companycity: category.companycity,
      companydescription: category.companydescription,
      membertype: category.assignedmembertype.map((type) => type.membertype),
      profilepic: category.profilepic,

    });
  }, [show]); // Update form fields when the modal is shown
  
  useEffect(() => {
    // Update referedDetails when the modal is opened for editing
    if (show && category && category.refereddetails) {
      setReferedDetails(category.refereddetails);
    }
  }, [show, category]);

  const handleSaveChanges = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.tomorrowlandclub.com/api/v1/client/editClientDetails",
        values,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 401 || response.status === 404) {
        // Redirect to login page for unauthorized or not found errors
        // navigate("/AdminLogin");
        return;
      }

      if (response.data.success) {
        console.log("Category updated successfully");
        onSave(values); // Notify parent component about the update
        // await fetchData();
        onHide();
      } else {
        console.error("Failed to update category");
      }

      setLoading(false);
      notification.success({
        message: 'Member details updated successfully.',
      });
      fetchData();
      onHide(); // Close the modal
    } catch (error) {
      console.error("Error updating category:", error);
      setLoading(false);
    }
  };

  
  const handleFileUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const maxFileSize = 1024 * 1024;
		if (file.size > maxFileSize) {
			notification.error({
				message: 'Please upload an image with a size less than 1MB.',
			});
			return;
		}
    const formData = new FormData();
    formData.append("file", file);

    try {
        const response = await axios.post(
            "https://api.tomorrowlandclub.com/api/v1/fileupload/file",
            formData,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' for file uploads
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress({ percent: percentCompleted });
                },
            }
        );
        const organiserId = response.data.organiserId;

        // After successful file upload, make a subsequent API call
        
        const editProfileImageResponse = await axios.post(
            "https://api.tomorrowlandclub.com/api/v1/client/editprofileimage",
            {
              clientid: category.clientid, 
              fileid:organiserId,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            }
        );if (editProfileImageResponse.status === 200) {
    // Reload the page to reflect the changes
    window.location.reload();
  }

        // Handle the response of the editprofileimage API call as needed
        console.log("Edit Profile Image Response:", editProfileImageResponse.data);
    } catch (error) {
        onError("File upload failed");
    }
};
  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const response = await fetch(
          "https://api.tomorrowlandclub.com/api/v1/pincode/getcountry",
          {
            method: "PUT",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
        } else {
          console.error("Failed to get the list of countries");
        }
      } catch (error) {
        console.error("Error fetching the list of countries:", error);
      }
    };

    fetchCountryList();
  }, []);
  const handleCountryClick = (selectedValue) => {
    console.log("Selected Country:", selectedValue);
    // You can use the selectedValue as the selected country here
    setSelectedCountry(selectedValue);

    // Make the API call to fetch states based on the selected country
    if (selectedValue) {
      fetchStates(selectedValue);
    }
  };
  useEffect(() => {
    const fetchCountryListforCompany = async () => {
      try {
        const response = await fetch(
          "https://api.tomorrowlandclub.com/api/v1/pincode/getcountry",
          {
            method: "PUT",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCountryListforCompany(data);
        } else {
          console.error("Failed to get the list of countries");
        }
      } catch (error) {
        console.error("Error fetching the list of countries:", error);
      }
    };

    fetchCountryListforCompany();
  }, []);
  const handleCountryClickforCompany = (selectedValue) => {
    console.log("Selected Country:", selectedValue);
    // You can use the selectedValue as the selected country here
    setSelectedCountryforCompany(selectedValue);

    // Make the API call to fetch states based on the selected country
    if (selectedValue) {
      fetchStatesforCompnany(selectedValue);
    }
  };
  const fetchStates = async (selectedCountry) => {
    try {
      const response = await axios.put(
        "https://api.tomorrowlandclub.com/api/v1/pincode/getstate",
        { country : selectedCountry },
        {
          headers: {  
            "Content-Type": "application/json",
          },
        }
      );

      // Set the fetched states in state
      if (response.status === 200) {
        setStateList(response.data);
      } else {
        console.error("Failed to get the list of states");
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const handleStateClick = (selectedValue1) => {
    // You can use the selectedValue as the selected country here
    setSelectedState(selectedValue1);

    // Make the API call to fetch states based on the selected country
    if (selectedValue1) {
      fetchPincodes(selectedCountry, selectedValue1);
    }
  };
  const fetchStatesforCompnany = async (selectedCountry) => {
    try {
      const response = await axios.put(
        "https://api.tomorrowlandclub.com/api/v1/pincode/getstate",
        { country : selectedCountry },
        {
          headers: {  
            "Content-Type": "application/json",
          },
        }
      );

      // Set the fetched states in state
      if (response.status === 200) {
        setStateListforCompany(response.data);
      } else {
        console.error("Failed to get the list of states");
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const handleStateClickforCompany = (selectedValue1) => {
    // You can use the selectedValue as the selected country here
    setSelectedStateforCompany(selectedValue1);

    // Make the API call to fetch states based on the selected country
    if (selectedValue1) {
      fetchPincodesforCompany(selectedCountryforCompany, selectedValue1);
    }
  };
  const fetchPincodes = async (selectedCountry, selectedState) => {
    try {
      const response = await axios.put(
        "https://api.tomorrowlandclub.com/api/v1/pincode/getpincode",
        {
          country: selectedCountry,
          state: selectedState,
        }
      );

      if (response.status === 200) {
        console.log("Pincode Response:", response.data);
        setPincodeList(response.data);
      } else {
        console.error("Failed to get the list of pincodes");
      }
    } catch (error) {
      console.error("Error fetching the list of pincodes:", error);
    }
  };
  const fetchPincodesforCompany = async (selectedCountryforCompany, selectedStateforCompany) => {
    try {
      const response = await axios.put(
        "https://api.tomorrowlandclub.com/api/v1/pincode/getpincode",
        {
          country: selectedCountryforCompany,
          state: selectedStateforCompany,
        }
      );

      if (response.status === 200) {
        console.log("Pincode Response:", response.data);
        setPincodeListforCompany(response.data);
      } else {
        console.error("Failed to get the list of pincodes");
      }
    } catch (error) {
      console.error("Error fetching the list of pincodes:", error);
    }
  };
  const handleKovilChange = (value) => {
    setKovil(value);

    switch (value) {
      case 'Vairavan Kovil':
        setPerivu(["Sirukulattur", "Kalanivasal", "Maruttendrapuram"]);
        break;
      case 'Ilayathakudi':
        setPerivu(["Okkur", "Arumburkolar (alias Pattanasamy)", "Perumarudur", "Kinkanikkur", "Kalanivasal", "Perasandur", "Sirusettur"]);
        break;
      case 'Mathur':
        setPerivu(["Uraiyur", "Arumbakkur", "Manalur", "Mannur", "Kannur", "Karuppur", "Kulattur"]);
        break;
      default:
        setPerivu([]);
    }
  };
  const handleKovilChangeforSpouse = (value) => {
    switch (value) {
      case 'Vairavan Kovil':
        setspouseperivu(["Sirukulattur", "Kalanivasal", "Maruttendrapuram"]);
        break;
      case 'Ilayathakudi':
        setspouseperivu(["Okkur", "Arumburkolar (alias Pattanasamy)", "Perumarudur", "Kinkanikkur", "Kalanivasal", "Perasandur", "Sirusettur"]);
        break;
      case 'Mathur':
        setspouseperivu(["Uraiyur", "Arumbakkur", "Manalur", "Mannur", "Kannur", "Karuppur", "Kulattur"]);
        break;
      default:
        setspouseperivu([]);
    }
  };
  const handleKovilChangeforFather = (value) => {
    switch (value) {
      case 'Vairavan Kovil':
        setfathersperivu(["Sirukulattur", "Kalanivasal", "Maruttendrapuram"]);
        break;
      case 'Ilayathakudi':
        setfathersperivu(["Okkur", "Arumburkolar (alias Pattanasamy)", "Perumarudur", "Kinkanikkur", "Kalanivasal", "Perasandur", "Sirusettur"]);
        break;
      case 'Mathur':
        setfathersperivu(["Uraiyur", "Arumbakkur", "Manalur", "Mannur", "Kannur", "Karuppur", "Kulattur"]);
        break;
      default:
        setfathersperivu([]);
    }
  };
  const handleMobileChangeLogin = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) && newValue.length <= 10) {
      setMobile(newValue);
    }
  };
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(newEmail);
    setIsValidEmail(isValid);
  };
  const handleMobileChangeLoginforCompany = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) && newValue.length <= 10) {
      setMobileforCompany(newValue);
    }
  };
  const handleEmailChangeforCompany= (event) => {
    const newEmail = event.target.value;
    setEmailforComapny(newEmail);

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(newEmail);
    setIsValidEmailforCompany(isValid);
  };
  const fetchDataforcategory = async () => {
		try {
		  const response = await axios.put("https://api.tomorrowlandclub.com/api/v1/companycategory/get",{},{
			
		  headers: {
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			navigate('/AdminLogin');
			return; 
		  }
		  setData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };

    useEffect(() => {
      fetchDataforcategory();
      }, []); 
      useEffect(() => {
        const fetchMemberTypes = async () => {
          try {
            const response = await axios.put('https://api.tomorrowlandclub.com/api/v1/membertype/get');
            setMemberTypes(response.data);
          } catch (error) {
            console.error('Error fetching member types:', error);
          }
        };
    
        fetchMemberTypes();
      }, []);
      const handleAcceptReferral = async (acc) => {
        try {
          const response = await axios.put(
            "https://api.tomorrowlandclub.com/api/v1/referal/request",
            { referenceid: selectedReferral.referenceid, accepted: acc },
            { headers: { "Content-Type": "application/json" } }
          );
      
          if (response.status === 200) {
            console.log("Accepted");
            window.location.reload();
            // Perform additional actions if needed
          } else {
            console.error("API error:", response);
            // Handle error, set an error state, etc.
          }
        } catch (error) {
          console.error("Error making API call:", error);
          // Handle error, set an error state, etc.
        }
      };
      const handleEditReferral = (referedDetail) => {
        // Handle edit referral logic here
        setSelectedReferral(referedDetail);
      };
  return (
    <Modal
      title="Edit Member Details"
      visible={show}
      onCancel={onHide}
      footer={[
        <Button key="cancel" onClick={onHide}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()} loading={loading}>
          Save Changes
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={handleSaveChanges}
        initialValues={category}
      >
        <Form.Item name="clientid" style={{ display: 'none' }}>
          <Input type="text" />
        </Form.Item>
        <Form.Item label="Member ID" name="idno">
          <Input />
        </Form.Item>
        <Form.Item label="First Name" name="firstname">
          <Input />
        </Form.Item>
        <Form.Item label="Last Name" name="lastname">
          <Input />
        </Form.Item>
        <Form.Item label="Gender" name="gender">
  <Select placeholder="Select">
    <Option value="male">Male</Option>
    <Option value="female">Female</Option>
  </Select>
</Form.Item>
<Form.Item label="Marital Status" name="maritalstatus">
  <Select placeholder="Select">
    <Option value="single">Single</Option>
    <Option value="married">Married</Option>
    <Option value="widowed">Widowed</Option>
    <Option value="divorced">Divorced</Option>
  </Select>
</Form.Item>
        <Form.Item label="Address 1" name="address1">
          <Input />
        </Form.Item>
        <Form.Item label="Area" name="area">
          <Input />
        </Form.Item>
        <Form.Item label="City" name="city">
          <Input />
        </Form.Item>
        <Form.Item label="Country" name="country">
  <Select 
    onSelect={(value) => handleCountryClick(value)} 
    placeholder="Select a country"
  >
    {countryList.map(country => (
      <Select.Option key={country.pincodeid} value={country.country}>
        {country.country}
      </Select.Option>
    ))}
  </Select>
</Form.Item>
<Form.Item label="State" name="state">
  <Select 
    onSelect={(value) => handleStateClick(value)} 
    placeholder="Select a state"
  >
    {stateList.map(state => (
      <Select.Option key={state.pincodeid} value={state.state}>
        {state.state}
      </Select.Option>
    ))}
  </Select>
</Form.Item>
<Form.Item label="Pincode" name="pincode">
    <Select>
        {pincodeList.map(pincode => (
            <Select.Option key={pincode.pincodeid} value={pincode.pincode}>
                {pincode.pincode}
            </Select.Option>
        ))}
    </Select>
</Form.Item>
<Form.Item label="Native" name="nativee">
  <Select 
    onChange={(value) => setNative(value)} 
    value={nativee} 
    placeholder="Select a native"
  >
    <Option value="Alagapuri (Kottaiyur)">Alagapuri (Kottaiyur)</Option>
    <Option value="Alavakottai">Alavakottai</Option>
    <Option value="Amaravathipudur">Amaravathipudur</Option>
    <Option value="Aranmanai Siruvayal">Aranmanai Siruvayal</Option>
    <Option value="Arimalam">Arimalam</Option>
    <Option value="Ariyakudi">Ariyakudi</Option>
    <Option value="Athikkadu Thekkur">Athikkadu Thekkur</Option>
    <Option value="Attangudi">Attangudi</Option>
    <Option value="Attangudi Muthupattinam">Attangudi Muthupattinam</Option>
    <Option value="Chockalingam Pudur">Chockalingam Pudur</Option>
    <Option value="Chocknathapuram">Chocknathapuram</Option>
    <Option value="Devakottai">Devakottai</Option>
    <Option value="Kaliarmangalam">Kaliarmangalam</Option>
    <Option value="Kallal">Kallal</Option>
    <Option value="Kandavarayanpatti">Kandavarayanpatti</Option>
    <Option value="Kandanur">Kandanur</Option>
    <Option value="Karaikudi">Karaikudi</Option>
    <Option value="Karunkulam">Karunkulam</Option>
    <Option value="Kilapungudi">Kilapungudi</Option>
    <Option value="Kilasivalpatti">Kilasivalpatti</Option>
    <Option value="P.Alagapuri">P.Alagapuri</Option>
    <Option value="Kollangudi Alagapuri">Kollangudi Alagapuri</Option>
    <Option value="Konapet">Konapet</Option>
    <Option value="Koppanapatti">Koppanapatti</Option>
    <Option value="Kottamangalam">Kottamangalam</Option>
    <Option value="Kottaiyur">Kottaiyur</Option>
    <Option value="Kulipirai">Kulipirai</Option>
    <Option value="Kuruvikondanpatti">Kuruvikondanpatti</Option>
    <Option value="Lakshmipuram">Lakshmipuram</Option>
    <Option value="Kothamangalam Lakshipuram">Kothamangalam Lakshipuram</Option>
    <Option value="Chockalingapuram">Chockalingapuram</Option>
    <Option value="Mahibalanpatti">Mahibalanpatti</Option>
    <Option value="Managiri">Managiri</Option>
    <Option value="Melasivapuri">Melasivapuri</Option>
    <Option value="Mithalaipatti">Mithalaipatti</Option>
    <Option value="Nachundupatti">Nachundupatti</Option>
    <Option value="Nachiapuram">Nachiapuram</Option>
    <Option value="Natarajapuram">Natarajapuram</Option>
    <Option value="Nemathanpatti">Nemathanpatti</Option>
    <Option value="Nerkupai">Nerkupai</Option>
    <Option value="Okkur">Okkur</Option>
    <Option value="Oyakondan Siruvayal">Oyakondan Siruvayal</Option>
    <Option value="Panageri">Panageri</Option>
    <Option value="Palavangudi">Palavangudi</Option>
    <Option value="Pallathur">Pallathur</Option>
    <Option value="Panagudi">Panagudi</Option>
    <Option value="Panayapatti">Panayapatti</Option>
    <Option value="Pariamaruthapatti">Pariamaruthapatti</Option>
    <Option value="Pattamangalam">Pattamangalam</Option>
    <Option value="Pillaiyarpatti">Pillaiyarpatti</Option>
    <Option value="Ponnamaravathy">Ponnamaravathy</Option>
    <Option value="Pulankurichi">Pulankurichi</Option>
    <Option value="Pudupatti (Ponnamaravathy)">Pudupatti (Ponnamaravathy)</Option>
    <Option value="Puduvayal">Puduvayal</Option>
    <Option value="Ramachandrapuram">Ramachandrapuram</Option>
    <Option value="Rangiam">Rangiam</Option>
    <Option value="Rajavaram">Rajavaram</Option>
    <Option value="Sakkandi">Sakkandi</Option>
    <Option value="Sembanur">Sembanur</Option>
    <Option value="Sevvur">Sevvur</Option>
    <Option value="Shanmuganathapuram (Aravayal)">Shanmuganathapuram (Aravayal)</Option>
    <Option value="Siravayal">Siravayal</Option>
    <Option value="Sirukudalpatti">Sirukudalpatti</Option>
    <Option value="Solapuram">Solapuram</Option>
    <Option value="Thanichavurani">Thanichavurani</Option>
    <Option value="Thenipatti">Thenipatti</Option>
    <Option value="Ulagampatti">Ulagampatti</Option>
    <Option value="Valayapatti">Valayapatti</Option>
    <Option value="Vegupatti">Vegupatti</Option>
    <Option value="Veendhampatti">Veendhampatti</Option>
    <Option value="Vetriyur">Vetriyur</Option>
    <Option value="Virachalai">Virachalai</Option>
    <Option value="Viramathi (Kilasivalpatti)">Viramathi (Kilasivalpatti)</Option>
    <Option value="V.Lakshipuram (Virachalai)">V.Lakshipuram (Virachalai)</Option>
  </Select>
</Form.Item>
<Form.Item label="Kovil" name="kovil">
  <Select 
     onChange={(value) => handleKovilChange(value)}
    placeholder="Select a kovil"
  >
    <Option value="Vairavan Kovil">Vairavan Kovil</Option>
    <Option value="Ilayathakudi">Ilayathakudi</Option>
    <Option value="Mathur">Mathur</Option>
    <Option value="Iraniyur">Iraniyur</Option>
    <Option value="Pillaiyarpatti">Pillaiyarpatti</Option>
    <Option value="Soorakudi">Soorakudi</Option>
    <Option value="Iluppaikkudi">Iluppaikkudi</Option>
    <Option value="Nemam">Nemam</Option>
    <Option value="Velankudi">Velankudi</Option>
  </Select>
</Form.Item>
<Form.Item label="Perivu" name="perivu">
  <Select 
    onChange={(value) => setSelectedPerivu(value)} 
    value={selectedPerivu} 
    placeholder="Select a perivu"
  >
    {perivu.map((option, index) => (
      <Option key={index} value={option}>
        {option}
      </Option>
    ))}
  </Select>
</Form.Item>

<Form.Item label="Mobile" name="mobile" validateStatus={mobile.length !== 10 && mobile.length > 0 ? "error" : ""}
  help={mobile.length !== 10 && mobile.length > 0 && "Please enter 10 digits"}>
          <Input  type="tel"
    name="text"
    className={`form-control ${
      mobile.length !== 10 && mobile.length > 0 ? "custom-invalid" : ""
    }`}  onChange={handleMobileChangeLogin}/>
        </Form.Item>
        <Form.Item
  label="Email" name="email"
  validateStatus={!isValidEmail ? "error" : ""}
  help={!isValidEmail && "Please enter a valid email address"}
  
>
  <Input
  type="email"
  name="email"
  className="form-control"
  placeholder="enter"
  value={email}
  onChange={handleEmailChange}
    
  />
</Form.Item>

<Form.Item label="Proposer Name" name="proposername">
          <Input />
        </Form.Item>
        <Form.Item label="secordorname" name="secordorname">
          <Input />
        </Form.Item>
        <Form.Item label="Year of Joining" name="yearofjoining">
          <Input />
        </Form.Item>

        <Form.Item label="Spouse Name" name="spousename">
          <Input />
        </Form.Item>

        <Form.Item label="Spouse Native" name="spousenativee">
  <Select 
    onChange={(value) => setNativeforSpouse(value)} 
    value={spousenativee} 
    placeholder="Select a native"
  >
    <Option value="Alagapuri (Kottaiyur)">Alagapuri (Kottaiyur)</Option>
    <Option value="Alavakottai">Alavakottai</Option>
    <Option value="Amaravathipudur">Amaravathipudur</Option>
    <Option value="Aranmanai Siruvayal">Aranmanai Siruvayal</Option>
    <Option value="Arimalam">Arimalam</Option>
    <Option value="Ariyakudi">Ariyakudi</Option>
    <Option value="Athikkadu Thekkur">Athikkadu Thekkur</Option>
    <Option value="Attangudi">Attangudi</Option>
    <Option value="Attangudi Muthupattinam">Attangudi Muthupattinam</Option>
    <Option value="Chockalingam Pudur">Chockalingam Pudur</Option>
    <Option value="Chocknathapuram">Chocknathapuram</Option>
    <Option value="Devakottai">Devakottai</Option>
    <Option value="Kaliarmangalam">Kaliarmangalam</Option>
    <Option value="Kallal">Kallal</Option>
    <Option value="Kandavarayanpatti">Kandavarayanpatti</Option>
    <Option value="Kandanur">Kandanur</Option>
    <Option value="Karaikudi">Karaikudi</Option>
    <Option value="Karunkulam">Karunkulam</Option>
    <Option value="Kilapungudi">Kilapungudi</Option>
    <Option value="Kilasivalpatti">Kilasivalpatti</Option>
    <Option value="P.Alagapuri">P.Alagapuri</Option>
    <Option value="Kollangudi Alagapuri">Kollangudi Alagapuri</Option>
    <Option value="Konapet">Konapet</Option>
    <Option value="Koppanapatti">Koppanapatti</Option>
    <Option value="Kottamangalam">Kottamangalam</Option>
    <Option value="Kottaiyur">Kottaiyur</Option>
    <Option value="Kulipirai">Kulipirai</Option>
    <Option value="Kuruvikondanpatti">Kuruvikondanpatti</Option>
    <Option value="Lakshmipuram">Lakshmipuram</Option>
    <Option value="Kothamangalam Lakshipuram">Kothamangalam Lakshipuram</Option>
    <Option value="Chockalingapuram">Chockalingapuram</Option>
    <Option value="Mahibalanpatti">Mahibalanpatti</Option>
    <Option value="Managiri">Managiri</Option>
    <Option value="Melasivapuri">Melasivapuri</Option>
    <Option value="Mithalaipatti">Mithalaipatti</Option>
    <Option value="Nachundupatti">Nachundupatti</Option>
    <Option value="Nachiapuram">Nachiapuram</Option>
    <Option value="Natarajapuram">Natarajapuram</Option>
    <Option value="Nemathanpatti">Nemathanpatti</Option>
    <Option value="Nerkupai">Nerkupai</Option>
    <Option value="Okkur">Okkur</Option>
    <Option value="Oyakondan Siruvayal">Oyakondan Siruvayal</Option>
    <Option value="Panageri">Panageri</Option>
    <Option value="Palavangudi">Palavangudi</Option>
    <Option value="Pallathur">Pallathur</Option>
    <Option value="Panagudi">Panagudi</Option>
    <Option value="Panayapatti">Panayapatti</Option>
    <Option value="Pariamaruthapatti">Pariamaruthapatti</Option>
    <Option value="Pattamangalam">Pattamangalam</Option>
    <Option value="Pillaiyarpatti">Pillaiyarpatti</Option>
    <Option value="Ponnamaravathy">Ponnamaravathy</Option>
    <Option value="Pulankurichi">Pulankurichi</Option>
    <Option value="Pudupatti (Ponnamaravathy)">Pudupatti (Ponnamaravathy)</Option>
    <Option value="Puduvayal">Puduvayal</Option>
    <Option value="Ramachandrapuram">Ramachandrapuram</Option>
    <Option value="Rangiam">Rangiam</Option>
    <Option value="Rajavaram">Rajavaram</Option>
    <Option value="Sakkandi">Sakkandi</Option>
    <Option value="Sembanur">Sembanur</Option>
    <Option value="Sevvur">Sevvur</Option>
    <Option value="Shanmuganathapuram (Aravayal)">Shanmuganathapuram (Aravayal)</Option>
    <Option value="Siravayal">Siravayal</Option>
    <Option value="Sirukudalpatti">Sirukudalpatti</Option>
    <Option value="Solapuram">Solapuram</Option>
    <Option value="Thanichavurani">Thanichavurani</Option>
    <Option value="Thenipatti">Thenipatti</Option>
    <Option value="Ulagampatti">Ulagampatti</Option>
    <Option value="Valayapatti">Valayapatti</Option>
    <Option value="Vegupatti">Vegupatti</Option>
    <Option value="Veendhampatti">Veendhampatti</Option>
    <Option value="Vetriyur">Vetriyur</Option>
    <Option value="Virachalai">Virachalai</Option>
    <Option value="Viramathi (Kilasivalpatti)">Viramathi (Kilasivalpatti)</Option>
    <Option value="V.Lakshipuram (Virachalai)">V.Lakshipuram (Virachalai)</Option>
  </Select>
</Form.Item>

<Form.Item label="Spouse Kovil" name="spousekovil">
  <Select 
     onChange={(value) => handleKovilChangeforSpouse(value)}
    placeholder="Select a kovil"
  >
    <Option value="Vairavan Kovil">Vairavan Kovil</Option>
    <Option value="Ilayathakudi">Ilayathakudi</Option>
    <Option value="Mathur">Mathur</Option>
    <Option value="Iraniyur">Iraniyur</Option>
    <Option value="Pillaiyarpatti">Pillaiyarpatti</Option>
    <Option value="Soorakudi">Soorakudi</Option>
    <Option value="Iluppaikkudi">Iluppaikkudi</Option>
    <Option value="Nemam">Nemam</Option>
    <Option value="Velankudi">Velankudi</Option>
  </Select>
</Form.Item>
<Form.Item label="Spouse Perivu" name="spouseperivu">
  <Select 
    onChange={(value) => setSelectedPerivuforSpouse(value)} 
    value={selectedPerivuforSpouse} 
    placeholder="Select a perivu"
  >
    {spouseperivu.map((option, index) => (
      <Option key={index} value={option}>
        {option}
      </Option>
    ))}
  </Select>
</Form.Item>
        <Form.Item label="Father Name" name="fathersname">
          <Input />
        </Form.Item>
         <Form.Item label="Father Native" name="fathersnativee">
  <Select 
    onChange={(value) => setfathersnativee(value)} 
    value={fathersnativee} 
    placeholder="Select a native"
  >
    <Option value="Alagapuri (Kottaiyur)">Alagapuri (Kottaiyur)</Option>
    <Option value="Alavakottai">Alavakottai</Option>
    <Option value="Amaravathipudur">Amaravathipudur</Option>
    <Option value="Aranmanai Siruvayal">Aranmanai Siruvayal</Option>
    <Option value="Arimalam">Arimalam</Option>
    <Option value="Ariyakudi">Ariyakudi</Option>
    <Option value="Athikkadu Thekkur">Athikkadu Thekkur</Option>
    <Option value="Attangudi">Attangudi</Option>
    <Option value="Attangudi Muthupattinam">Attangudi Muthupattinam</Option>
    <Option value="Chockalingam Pudur">Chockalingam Pudur</Option>
    <Option value="Chocknathapuram">Chocknathapuram</Option>
    <Option value="Devakottai">Devakottai</Option>
    <Option value="Kaliarmangalam">Kaliarmangalam</Option>
    <Option value="Kallal">Kallal</Option>
    <Option value="Kandavarayanpatti">Kandavarayanpatti</Option>
    <Option value="Kandanur">Kandanur</Option>
    <Option value="Karaikudi">Karaikudi</Option>
    <Option value="Karunkulam">Karunkulam</Option>
    <Option value="Kilapungudi">Kilapungudi</Option>
    <Option value="Kilasivalpatti">Kilasivalpatti</Option>
    <Option value="P.Alagapuri">P.Alagapuri</Option>
    <Option value="Kollangudi Alagapuri">Kollangudi Alagapuri</Option>
    <Option value="Konapet">Konapet</Option>
    <Option value="Koppanapatti">Koppanapatti</Option>
    <Option value="Kottamangalam">Kottamangalam</Option>
    <Option value="Kottaiyur">Kottaiyur</Option>
    <Option value="Kulipirai">Kulipirai</Option>
    <Option value="Kuruvikondanpatti">Kuruvikondanpatti</Option>
    <Option value="Lakshmipuram">Lakshmipuram</Option>
    <Option value="Kothamangalam Lakshipuram">Kothamangalam Lakshipuram</Option>
    <Option value="Chockalingapuram">Chockalingapuram</Option>
    <Option value="Mahibalanpatti">Mahibalanpatti</Option>
    <Option value="Managiri">Managiri</Option>
    <Option value="Melasivapuri">Melasivapuri</Option>
    <Option value="Mithalaipatti">Mithalaipatti</Option>
    <Option value="Nachundupatti">Nachundupatti</Option>
    <Option value="Nachiapuram">Nachiapuram</Option>
    <Option value="Natarajapuram">Natarajapuram</Option>
    <Option value="Nemathanpatti">Nemathanpatti</Option>
    <Option value="Nerkupai">Nerkupai</Option>
    <Option value="Okkur">Okkur</Option>
    <Option value="Oyakondan Siruvayal">Oyakondan Siruvayal</Option>
    <Option value="Panageri">Panageri</Option>
    <Option value="Palavangudi">Palavangudi</Option>
    <Option value="Pallathur">Pallathur</Option>
    <Option value="Panagudi">Panagudi</Option>
    <Option value="Panayapatti">Panayapatti</Option>
    <Option value="Pariamaruthapatti">Pariamaruthapatti</Option>
    <Option value="Pattamangalam">Pattamangalam</Option>
    <Option value="Pillaiyarpatti">Pillaiyarpatti</Option>
    <Option value="Ponnamaravathy">Ponnamaravathy</Option>
    <Option value="Pulankurichi">Pulankurichi</Option>
    <Option value="Pudupatti (Ponnamaravathy)">Pudupatti (Ponnamaravathy)</Option>
    <Option value="Puduvayal">Puduvayal</Option>
    <Option value="Ramachandrapuram">Ramachandrapuram</Option>
    <Option value="Rangiam">Rangiam</Option>
    <Option value="Rajavaram">Rajavaram</Option>
    <Option value="Sakkandi">Sakkandi</Option>
    <Option value="Sembanur">Sembanur</Option>
    <Option value="Sevvur">Sevvur</Option>
    <Option value="Shanmuganathapuram (Aravayal)">Shanmuganathapuram (Aravayal)</Option>
    <Option value="Siravayal">Siravayal</Option>
    <Option value="Sirukudalpatti">Sirukudalpatti</Option>
    <Option value="Solapuram">Solapuram</Option>
    <Option value="Thanichavurani">Thanichavurani</Option>
    <Option value="Thenipatti">Thenipatti</Option>
    <Option value="Ulagampatti">Ulagampatti</Option>
    <Option value="Valayapatti">Valayapatti</Option>
    <Option value="Vegupatti">Vegupatti</Option>
    <Option value="Veendhampatti">Veendhampatti</Option>
    <Option value="Vetriyur">Vetriyur</Option>
    <Option value="Virachalai">Virachalai</Option>
    <Option value="Viramathi (Kilasivalpatti)">Viramathi (Kilasivalpatti)</Option>
    <Option value="V.Lakshipuram (Virachalai)">V.Lakshipuram (Virachalai)</Option>
  </Select>
</Form.Item>
<Form.Item label="Father Kovil" name="fatherskovil">
  <Select 
     onChange={(value) => handleKovilChangeforFather(value)}
    placeholder="Select a kovil"
  >
    <Option value="Vairavan Kovil">Vairavan Kovil</Option>
    <Option value="Ilayathakudi">Ilayathakudi</Option>
    <Option value="Mathur">Mathur</Option>
    <Option value="Iraniyur">Iraniyur</Option>
    <Option value="Pillaiyarpatti">Pillaiyarpatti</Option>
    <Option value="Soorakudi">Soorakudi</Option>
    <Option value="Iluppaikkudi">Iluppaikkudi</Option>
    <Option value="Nemam">Nemam</Option>
    <Option value="Velankudi">Velankudi</Option>
  </Select>
</Form.Item>
<Form.Item label="Father Perivu" name="fathersperivu">
  <Select 
    onChange={(value) => setSelectedPerivuforFather(value)} 
    value={selectedPerivuforFather} 
    placeholder="Select a perivu"
  >
    {fathersperivu.map((option, index) => (
      <Option key={index} value={option}>
        {option}
      </Option>
    ))}
  </Select>
</Form.Item>

<Form.Item label="Company Name" name="companyname">
          <Input />
        </Form.Item>
        <Form.Item label="Category" name="companycategory">
  <Select
    style={{ width: "100%", height: "50px" }}
    placeholder="Select category"
    value={companycategory}
    onChange={(value) => setCompanycategory(value)}
  >
    {data.map(category => (
      <Option key={category.companycategoryid} value={category.companycategory}>
        {category.companycategory}
      </Option>
    ))}
  </Select>
</Form.Item>
        <Form.Item label="Landline" name="companylandline">
          <Input />
        </Form.Item>
        <Form.Item label="Company Mobile" name="companymobile" validateStatus={companymobile.length !== 10 && companymobile.length > 0 ? "error" : ""}
  help={companymobile.length !== 10 && companymobile.length > 0 && "Please enter 10 digits"}>
          <Input  type="tel"
    name="text"
    className={`form-control ${
      companymobile.length !== 10 && companymobile.length > 0 ? "custom-invalid" : ""
    }`}  onChange={handleMobileChangeLoginforCompany}/>
        </Form.Item>
        <Form.Item
  label="Email" name="companyemail"
  validateStatus={!isValidEmailforCompany ? "error" : ""}
  help={!isValidEmailforCompany && "Please enter a valid email address"}
  
>
  <Input
  type="email"
  name="companyemail"
  className="form-control"
  placeholder="enter"
  value={companyemail}
  onChange={handleEmailChangeforCompany}
    
  />
</Form.Item>
        <Form.Item label="Website Address" name="companywebsite">
          <Input />
        </Form.Item>
        <Form.Item label="Company Description" name="companydescription">
          <Input />
        </Form.Item>
        <Form.Item label="Address 1" name="companyaddress1">
          <Input />
        </Form.Item>
        <Form.Item label="Area" name="companyarea">
          <Input />
        </Form.Item>
        <Form.Item label="City" name="companycity">
          <Input />
        </Form.Item>
        <Form.Item label="Country" name="companycountry">
  <Select 
    onSelect={(value) => handleCountryClickforCompany(value)} 
    placeholder="Select a country"
  >
    {countryListforCompany.map(country => (
      <Select.Option key={country.pincodeid} value={country.country}>
        {country.country}
      </Select.Option>
    ))}
  </Select>
</Form.Item>
<Form.Item label="State" name="companystate">
  <Select 
    onSelect={(value) => handleStateClickforCompany(value)} 
    placeholder="Select a state"
  >
    {stateListforCompany.map(state => (
      <Select.Option key={state.pincodeid} value={state.state}>
        {state.state}
      </Select.Option>
    ))}
  </Select>
</Form.Item>
<Form.Item label="Pincode" name="companypincode">
    <Select>
        {pincodeListforCompany.map(pincode => (
            <Select.Option key={pincode.pincodeid} value={pincode.pincode}>
                {pincode.pincode}
            </Select.Option>
        ))}
    </Select>
</Form.Item>
<Form.Item label="Member Type" name="membertype">
<Select
    mode="multiple"
    style={{ width: '100%' }}
    placeholder="Select Member Types"
    value={selectedMemberType}
    onChange={(selectedValues) => setSelectedMemberType(selectedValues)}
    dropdownStyle={{ zIndex: 1056 }}
  >
    {memberTypes.map((type) => (
      <Option key={type.membertypeid} value={type.membertype}>
        {type.membertype}
      </Option>
    ))}
  </Select>

</Form.Item>
<Form.Item label="Profile Image" name="uploadfile" >
                    <img width={'75%'} height={'75%'} src={(profilepic !=null ? profilepic : noImage)} alt="profile-photo" class="img-fluid"/>
<Upload
                customRequest={handleFileUpload} 
                fileList={fileList}
                onChange={({ fileList }) => setFileList(fileList)}
                >
                  <Button className="photoUpload ml-3" icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Upload Photo</Button>
  </Upload>
</Form.Item>
<Form.Item label="Refered Details" name="referedDetails" >
<ul>
            {referedDetails.map((referedDetail) => (
              <li key={referedDetail.referenceid}>
                {`${referedDetail.name} - Status: ${
                  referedDetail.accepted === true ? 'Accepted' : 'Pending'
                }`}
                <Button variant="outline-primary" size="sm" onClick={() => handleEditReferral(referedDetail)}>
                  Edit
                </Button>
              </li>
            ))}
          </ul>
</Form.Item>{selectedReferral && (
          <div>
            <Button variant="success" onClick={() => handleAcceptReferral(true)}>
              Accept
            </Button>
            <Button variant="danger" onClick={() => handleAcceptReferral(false)}>
              Deny
            </Button>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default EditCategoryModal;
