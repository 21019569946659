import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'; 
import './style.scss';
import './ekka.scss';
import './materialdesignicons.scss'

const menuItems = [
	// { text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
	// Add more menu items as needed
  ];
const SideBar = () => {

      const [isSubMenuOpen, setIsSubMenuOpen] = useState({
		vendors: false,
		user: false,
		categories: false,
		products: false,
		orders: false,
		authentication: false,
		icons: false,
		otherpages: false,
		banner:false,
		offer:false,
		cinfo:false,
		cdetails:false,
		cminfo:false,
		coupon:false,
		pincode:false,
		deliverycharges:false,
		showpincode:false,
	  });
	  const toggleSubMenu = (menuName) => {
		setIsSubMenuOpen((prevState) => ({
		  ...prevState,
		  [menuName]: !prevState[menuName],
		}));
	  };
	  function handleLogout() {
		// Navigate to the login page
		window.location.href = '/AdminLogin'; 
	  }
	  
    return(
        <div class="ec-left-sidebar ec-bg-sidebar">
			<div id="sidebar" class="sidebar ec-sidebar-footer">

				<div class="ec-brand">
					<a href="index" title="Ekka">
						<img class="ec-brand-icon" src="assets/img/logo/ec-site-logo.png" alt="" />
						<span class="ec-brand-name text-truncate">Ekka</span>
					</a>
				</div>

				{/* <!-- begin sidebar scrollbar --> */}
				<div class="ec-navigation" data-simplebar>
					{/* <!-- sidebar menu --> */}
					<ul class="nav sidebar-inner" id="sidebar-menu">
						{/* <!-- Dashboard --> */}
						{menuItems.map((item, index) => (
                  <li key={index}>
                    <a className="sidenav-item-link" href={item.link}>
                      <i className={item.icon}></i>
                      <span className="nav-text">{item.text}</span>
                    </a>
                  </li>
                ))}
						{/* <!-- Vendors --> */}
						{/* <li className={`has-sub ${isSubMenuOpen.vendors ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('vendors')}>
          <i className="mdi mdi-account-group-outline"></i>
          <span className="nav-text">Vendors</span> <b className="caret"></b>
        </a>
        <div className={`collapse ${isSubMenuOpen.vendors ? 'show' : ''}`}>
          <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
            <li className="">
              <a className="sidenav-item-link" href="vendor-card">
                <span className="nav-text">Vendor Grid</span>
              </a>
            </li>

									<li class="">
										<a class="sidenav-item-link" href="vendor-list">
											<span class="nav-text">Vendor List</span>
										</a>
									</li>
									<li class="">
										<a class="sidenav-item-link" href="vendor-profile">
											<span class="nav-text">Vendors Profile</span>
										</a>
									</li>
								</ul>
							</div>
						</li> */}

						{/* <!-- Users --> */}
                        {/* <li className={`has-sub ${isSubMenuOpen.user ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('user')}>
          <i className="mdi mdi-account-group"></i>
          <span className="nav-text">Users</span> <b className="caret"></b>
        </a>
        <div className={`collapse ${isSubMenuOpen.user ? 'show' : ''}`}>
          <ul className="sub-menu" id="users" data-parent="#sidebar-menu">
                      <li>
                        <a className="sidenav-item-link" href="user-card">
                          <span className="nav-text">User Grid</span>
                        </a>
                      </li>

									<li class="">
										<a class="sidenav-item-link" href="user-list">
											<span class="nav-text">User List</span>
										</a>
									</li>
									<li class="">
										<a class="sidenav-item-link" href="user-profile">
											<span class="nav-text">Users Profile</span>
										</a>
									</li>
								</ul>
							</div>
							 <hr/>
						</li> */}

						{/* <!-- Category --> */}
                        {/* <li className={`has-sub ${isSubMenuOpen.categories ? 'expand' : ''}`}>
  <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('categories')}>
    <i className="mdi mdi-dns-outline"></i>
    <span className="nav-text">Categories</span> 
	<b className="caret"></b>
  </a>
  <div className={`collapse ${isSubMenuOpen.categories ? 'show' : ''}`}>
    <ul className="sub-menu" id="categorys" data-parent="#sidebar-menu">
      <li className="">
        <a className="sidenav-item-link" href="main-category">
          <span className="nav-text">Main Category</span>
        </a>
      </li>
      <li className="">
        <a className="sidenav-item-link" href="sub-category">
          <span className="nav-text">Sub Category</span>
        </a>
      </li>
	  <li className="">
        <a className="sidenav-item-link" href="subsub-category">
          <span className="nav-text">SubSub Category</span>
        </a>
      </li>
    </ul>
  </div>
</li> */}
{/* <li className={`has-sub ${isSubMenuOpen.categories ? 'expand' : ''}`}>
  <a href="main-category" className="sidenav-item-link">
    <i className="mdi mdi-account-group"></i>
    <span className="nav-text">Members</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.banner ? 'expand' : ''}`}>
  <a href="main-banner" className="sidenav-item-link">
    <i className="mdi mdi-account"></i>
    <span className="nav-text">
		Member Type
	</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.offer ? 'expand' : ''}`}>
  <a href="main-offer" className="sidenav-item-link">
    <i className="mdi mdi-dns-outline"></i>
    <span className="nav-text">
		Business Category
	</span>
  </a>
</li> */}
<li className={`has-sub ${isSubMenuOpen.offer ? 'expand' : ''}`}>
  <a href="events" className="sidenav-item-link">
    <i className="mdi mdi-dns-outline"></i>
    <span className="nav-text">
		Events
	</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.offer ? 'expand' : ''}`}>
  <a href="Booking" className="sidenav-item-link">
    <i className="mdi mdi-dns-outline"></i>
    <span className="nav-text">
		Booking
	</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.offer ? 'expand' : ''}`}>
  <a href="influencer" className="sidenav-item-link">
    <i className="mdi mdi-dns-outline"></i>
    <span className="nav-text">
		Influencer
	</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.offer ? 'expand' : ''}`}>
  <a href="employee" className="sidenav-item-link">
    <i className="mdi mdi-dns-outline"></i>
    <span className="nav-text">
		Employee
	</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.offer ? 'expand' : ''}`}>
  <a href="customer" className="sidenav-item-link">
    <i className="mdi mdi-dns-outline"></i>
    <span className="nav-text">
		Customer
	</span>
  </a>
</li>
{/* <li className={`has-sub ${isSubMenuOpen.categories ? 'expand' : ''}`}>
  <a href="main-company" className="sidenav-item-link">
    <i className="mdi mdi-account-group"></i>
    <span className="nav-text">Enquiry</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.categories ? 'expand' : ''}`}>
  <a href="banner" className="sidenav-item-link">
    <i className="mdi mdi-account-group"></i>
    <span className="nav-text">Banner</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.categories ? 'expand' : ''}`}>
  <a href="product-add" className="sidenav-item-link">
    <i className="mdi mdi-account-group"></i>
    <span className="nav-text">Pincodes</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.cinfo ? 'expand' : ''}`}>
  <a href="main-common" className="sidenav-item-link">
    <i className="mdi mdi-creative-commons"></i>
    <span className="nav-text">Social Media Icons</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.cinfo ? 'expand' : ''}`}>
  <a href="product-list" className="sidenav-item-link">
    <i className="mdi mdi-creative-commons"></i>
    <span className="nav-text">EC Members</span>
  </a>
</li> */}
						{/* <!-- Products --> */}
						{/* <li className={`has-sub ${isSubMenuOpen.products ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('products')}>
          <i className="mdi mdi-palette-advanced"></i>
          <span className="nav-text">Products</span> 
		  <b className="caret"> </b>
        </a>
        <div className={`collapse ${isSubMenuOpen.products ? 'show' : ''}`}>
          <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
                      <li className="">
                        <a className="sidenav-item-link" href="product-add">
                          <span className="nav-text">Add Product</span>
                        </a>
                      </li>
									<li class="">
										<a class="sidenav-item-link" href="product-list">
											<span class="nav-text">List Product</span>
										</a>
									</li>
							</div>
						</li> */}
						{/* <li className={`has-sub ${isSubMenuOpen.banner ? 'expand' : ''}`}>
  <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('banner')}>
    <i className="mdi mdi-camera-image"></i>
    <span className="nav-text">Banner</span> 
	<b className="caret"></b>
  </a>
  <div className={`collapse ${isSubMenuOpen.banner ? 'show' : ''}`}>
    <ul className="sub-menu" id="categorys" data-parent="#sidebar-menu">
      <li className="">
        <a className="sidenav-item-link" href="main-banner">
          <span className="nav-text">Add Banner</span>
        </a>
      </li>
	  <li className="">
        <a className="sidenav-item-link" href="main-offer">
          <span className="nav-text">Add Offer</span>
        </a>
      </li>
    </ul>
  </div>
</li> */}
{/* <li className={`has-sub ${isSubMenuOpen.cinfo ? 'expand' : ''}`}>
  <a href="main-common" className="sidenav-item-link">
    <i className="mdi mdi-creative-commons"></i>
    <span className="nav-text">Common Info</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.cdetails ? 'expand' : ''}`}>
  <a href="clientDetails" className="sidenav-item-link">
    <i className="mdi mdi-account-group"></i>
    <span className="nav-text">User</span>
  </a>
</li> */}
	{/* <!-- Orders --> */}
	{/* <li className={`has-sub ${isSubMenuOpen.orders ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('orders')}>
          <i className="mdi mdi-account-group-outline"></i>
          <span className="nav-text">Orders</span> <b className="caret"></b>
        </a>
        <div className={`collapse ${isSubMenuOpen.orders ? 'show' : ''}`}>
          <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
                      <li className="">
                        <a className="sidenav-item-link" href="new-order">
                          <span className="nav-text">New Order</span>
                        </a>
                      </li>
								</ul>
							</div>
						</li>
						<li className={`has-sub ${isSubMenuOpen.pincode ? 'expand' : ''}`}>
  <a href="main-pincodes" className="sidenav-item-link">
    <i className="mdi mdi-map-marker"></i>
    <span className="nav-text">Available Pincodes</span>
  </a>
</li> 
{/* <li className={`has-sub ${isSubMenuOpen.deliverycharges ? 'expand' : ''}`}>
  <a href="delivery-charges" className="sidenav-item-link">
    <i className="mdi mdi-currency-inr"></i>
    <span className="nav-text">Delivery Charges</span>
  </a>
</li>*/}
{/* <li className={`has-sub ${isSubMenuOpen.tcondition ? 'expand' : ''}`}>
  <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('tcondition')}>
    <i className="mdi mdi-image-filter-none"></i>
    <span className="nav-text">Other Pages</span> 
	<b className="caret"></b>
  </a>
  <div className={`collapse ${isSubMenuOpen.tcondition ? 'show' : ''}`}>
    <ul className="sub-menu" id="categorys" data-parent="#sidebar-menu">
      <li className="">
        <a className="sidenav-item-link" href="about-us">
          <span className="nav-text">About Us</span>
        </a>
      </li>
	 <li className="">
        <a className="sidenav-item-link" href="byelaw">
          <span className="nav-text">Bye Law</span>
        </a>
      </li>
	    <li className="">
        <a className="sidenav-item-link" href="customer-service">
          <span className="nav-text">Customer Service</span>
        </a>
      </li>
	  <li className="">
        <a className="sidenav-item-link" href="terms-conditions">
          <span className="nav-text">Terms & Conditions</span>
        </a>
      </li>
    </ul>
  </div>
</li>  */}


					

						
					</ul>
				</div>
			</div>
		</div>
	
    );
}
export default SideBar;