import React, { useState, useEffect } from 'react';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Input, Form, Upload, Button, DatePicker, notification } from 'antd';
import axios from "axios";
import { UploadOutlined } from '@ant-design/icons';
import EditSubCategoryModal from './EditSubCategory';
import moment from 'moment';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const qs = require('qs');

const Employee = () => {

	const token = localStorage.getItem('token');
	const [reloadList, setreloadList] = useState(false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [fileid, setUploadfiles] = useState([]);
	const [name, setName] = useState('');
	const [userName, setUserName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [mobile, setMobile] = useState('');
	const [date, setDate] = useState('');
	const [categoryList, setCategoriesList] = useState([]);
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);

	const setreloadListnow = () => {
		setreloadList(true);
	};

	const handleEditCategory = (category) => {
		function getAllFileIds(category) {
			if (category && category.files && Array.isArray(category.files)) {
				return category.files.map(file => file.fileid);
			} else {
				console.error("Invalid category object or missing 'files' property.");
				return [];
			}
		}
		let fileIds = getAllFileIds(category);
		sessionStorage.setItem('fileids', JSON.stringify(fileIds));
		setSelectedCategory(category);
		setShowEditModal(true);
	};

	const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	};

	const handleDeleteCategory = async (emp_id) => {
		try {
			const confirmDelete = window.confirm("Are you sure you want to delete this employee?");

			if (!confirmDelete) {
				return;
			}
			setLoading(true);
			const response = await axios.delete(
				'https://api.tomorrowlandclub.com/employees/deleteempbyid',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					data: qs.stringify({ emp_id: emp_id })
				}
			);
			if (response.status === 200) {
				console.log('Category deleted successfully');
				setreloadList(true);
			} else {
				console.error('Failed to delete category');
			}

			setLoading(false);
		} catch (error) {
			console.error('Error deleting category:', error);
			setLoading(false);
		}
	};

	const handleSaveEdit = async (editedData) => {
		try {
			setLoading(true);
			editedData.emp_id = selectedCategory.emp_id;
			delete editedData.files;
			const response = await axios.put(
				"https://api.tomorrowlandclub.com/employees/updateemp",
				editedData,
				{
					headers: {
						Authorization: 'Bearer ' + token,
						'Content-Type': 'application/x-www-form-urlencoded',
						'Access-Control-Allow-Origin': '*',
					},
				}
			);

			if (response.status === 401 || response.status === 404) {
				navigate('/AdminLogin');
				return;
			}

			if (response.status === 200) {
				console.log('Subcategory updated successfully');
				window.location.reload();
			} else {
				console.error('PUT request failed');
			}
		} catch (error) {
			console.error('Error updating data:', error);
		} finally {
			setLoading(false);
			handleCloseEditModal();
		}
	};

	useEffect(() => {
		fetchData();
	}, [reloadList]);

	const fetchData = async () => {
		try {
			const response = await axios.get("https://api.tomorrowlandclub.com/employees/allemp", {}, {

				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			});

			setreloadList(false);
			if (response.status === 401 || response.status === 404) {
				navigate('/AdminLogin');
				return;
			}
			setData(response.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const onFinish = async (e) => {
		e.preventDefault();
		const token = localStorage.getItem('token');
		try {
			const fileIdsArray = Array.isArray(fileid) ? fileid : [fileid];
			const formattedDate = date ? date.format('YYYY/MM/DD') : null;
			console.log(formattedDate);
			setLoading(true);
			const response = await fetch("https://api.tomorrowlandclub.com/employees/addemp", {
				method: "POST",
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				body: JSON.stringify({
					emp_id: 'emp_id',
					emp_Name: name,
					emp_EmailId: email,
					userName: userName,
					password: password,
					mobileNO: mobile,
					reg_Date: formattedDate,
				}),
			});

			if (response.status === 401 || response.status === 404) {
				navigate('/login');
				return;
			}

			if (response.ok) {
				const data = await response.json();
				const token = data.token;
				console.log("POST request successful");
				if (response.data && response.data.length > 0) {
					setData(response.data);
				} else {
					setData([]);
				}
				setLoading(false);
				localStorage.removeItem('organizerIds');
				fetchData();
				if (data.token) {
					localStorage.setItem('token', token);
				}
			} else {
				console.error("POST request failed");
			}
		} catch (error) {
			console.error("Error posting data:", error);
			setLoading(false);
		}
	};

	return (
		<div className="wrapper">
			<SideBar />
			<div className="ec-page-wrapper">
				<Header />
				<div className="ec-content-wrapper">
					<div className="content" style={{ marginTop: "50px" }}>
						<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>EMPLOYEE</h1>
							<p className="breadcrumbs">
								<span><a href="index">Home</a></span>
								<span><i className="mdi mdi-chevron-right"></i></span>Employee
							</p>
						</div>
						<div className="row">
							<div className="col-xl-4 col-lg-12">
								<div className="ec-cat-list card card-default mb-24px">
									<div className="card-body">
										<div className="ec-cat-form">
											<h4>Add Employee</h4>
											<form onSubmit={onFinish}>
												<div className="form-group row">
													<label htmlFor="slug" className="col-12 col-form-label">Employee Name</label>
													<div className="col-12">
														<input id="slug" name="slug" className="form-control here set-slug" type="text" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="slug" className="col-12 col-form-label">Email</label>
													<div className="col-12">
														<input id="slug" name="slug" className="form-control here set-slug" type="text" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="slug" className="col-12 col-form-label">User Name</label>
													<div className="col-12">
														<input id="slug" name="slug" className="form-control here set-slug" type="text" value={userName} onChange={(e) => setUserName(e.target.value)} autoComplete="off" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="slug" className="col-12 col-form-label">Password</label>
													<div className="col-12">
														<input id="slug" name="slug" className="form-control here set-slug" type="text" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="off" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="slug" className="col-12 col-form-label">Mobile No</label>
													<div className="col-12">
														<input id="slug" name="slug" className="form-control here set-slug" type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} autoComplete="off" />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-12 col-form-label">Date</label>
													<div className="col-12">
														<DatePicker
															onChange={(reg_Date) => setDate(reg_Date)}
															value={date}
															format="YYYY-MM-DD"
															placeholder="Select Date"
														/>
													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<button name="submit" type="submit" className="btn btn-primary">Submit</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-8 col-lg-12">
								<div className="ec-cat-list card card-default">
									<div className="card-body">
										<div className="table-responsive">
											<table id="responsive-data-table" className="table">
												<thead>
													<tr>
														<th>Employee ID</th>
														<th>Employee Name</th>
														<th>Email</th>
														<th>User Name</th>
														<th>Password</th>
														<th>Mobile No</th>
														<th>Reg.Date</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{data.map((category) => (
														<tr key={category.categoryid}>
															<td>{category.emp_id}</td>
															<td dangerouslySetInnerHTML={{ __html: category.emp_Name }}></td>
															<td dangerouslySetInnerHTML={{ __html: category.emp_EmailId }}></td>
															<td dangerouslySetInnerHTML={{ __html: category.userName }}></td>
															<td dangerouslySetInnerHTML={{ __html: category.password }}></td>
															<td dangerouslySetInnerHTML={{ __html: category.mobileNO }}></td>
															<td>{category.reg_Date}</td>
															<td>
																<div className="btn-group">
																	<a
																		href="#"
																		className="btn btn-outline-success"
																		onClick={(e) => {
																			e.preventDefault();
																			handleEditCategory(category);
																		}}
																	>
																		Edit
																	</a>
																	<a
																		href="#"
																		className="btn btn-outline-danger"
																		onClick={(e) => {
																			e.preventDefault();
																			handleDeleteCategory(category.emp_id);
																		}}
																	>
																		Delete
																	</a>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{selectedCategory && (
					<EditSubCategoryModal
						show={showEditModal}
						onHide={handleCloseEditModal}
						onSave={handleSaveEdit}
						categoryList={categoryList}
						category={selectedCategory}
						setreloadList={setreloadListnow}
					/>
				)}
			</div>
		</div>
	);
}
export default Employee;
