// EditSubCategory.js

import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';

const EditSubCategoryModal = ({
  show,
  onHide,
  onSave,
  categoryList,
  category,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      form.setFieldsValue(category);
    }
  }, [show, category, form]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const formData = await form.validateFields();
      onSave(formData);
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Edit Pincode"
      visible={show}
      onCancel={onHide}
      footer={[
        <Button key="cancel" onClick={onHide}>
          Cancel
        </Button>,
        <Button key="save" type="primary" loading={loading} onClick={handleSave}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="country" label="Country">
          <Input />
        </Form.Item>
        <Form.Item name="state" label="State">
          <Input />
        </Form.Item>
        <Form.Item name="pincode" label="Pincode">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditSubCategoryModal;
