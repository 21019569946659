import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import "./ekka.scss";
import SideBar from "./sidebar";
import Header from "./Header";
import { Select } from "antd";
const { Option } = Select;  
const CompanyInfo = () => {
  
  const token = localStorage.getItem('token');
	const [data, setData] = useState([]);
	const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState("showAll");

	  useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, [filterValue, token]); // Empty dependency array ensures the effect runs only once

    const fetchData = async () => {
      try {
        let apiUrl = "https://api.tomorrowlandclub.com/api/v1/commondetails/get";
        let requestBody = {};
  
        if (filterValue === "showCompleted") {
          requestBody = { contacted: true };
        } else if (filterValue === "showPending") {
          requestBody = { contacted: false };
        }
  
        const response = await axios.post(apiUrl, requestBody, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
  
        if (response.status === 401 || response.status === 404) {
          // Redirect to the login page for 401 or 404 errors
          navigate("/login");
          return; // Exit early to prevent further logic execution
        }
  
        const contactusids = response.data.map(
          (category) => category.contactusid
        );
  
        // Store contactusid values in localStorage
        localStorage.setItem("contactusids", JSON.stringify(contactusids));
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const handleFinishButtonClick = async (contactusid) => {
      try {
        const confirmed = window.confirm("Are you sure you want to mark this as completed?");
      if (!confirmed) {
        // If the user cancels, do nothing
        return;
      }
        const response = await axios.put(
          "https://api.tomorrowlandclub.com/api/v1/commondetails/add",
          {
            contactusid,
            contacted: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.status === 401 || response.status === 404) {
          // navigate("/login");
          return;
        }
  
        // Assuming your API returns updated data after marking as contacted
        // You may want to update the state or refetch data accordingly
        // ...
  
        console.log("POST request successful");
        window.location.reload();
      } catch (error) {
        console.error("Error finishing:", error);
      }
    };
    const handleFilterChange = (value) => {
      setFilterValue(value);
    };
    return(
        <div class="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>

		{/* <!-- PAGE WRAPPER --> */}
		<div class="ec-page-wrapper">

			{/* <!-- Header --> */}
			<Header/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div class="ec-content-wrapper">
				<div class="content" style={{marginTop:"50px"}}>
					<div class="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
						<h1>Contact Us</h1>
						<p class="breadcrumbs"><span><a href="index">Home</a></span>
							<span><i class="mdi mdi-chevron-right"></i></span>Contact Us</p>
					</div>
          <Select
              defaultValue="showAll"
              style={{ width: 120, marginBottom: 16 }}
              onChange={handleFilterChange}
            >
              <Option value="showAll">All</Option>
              <Option value="showCompleted">Completed</Option>
              <Option value="showPending">Pending</Option>
            </Select>
					<div class="row">
						<div class="col-xl-8 col-lg-12">
							<div class="ec-cat-list card card-default">
								<div class="card-body">
									<div class="table-responsive">
										<table id="responsive-data-table" class="table">
											<thead>
												<tr>
													<th>Name</th>
                                                    <th>Email</th>
                                                    <th>Subject</th>
													<th>Number</th>
													<th>Message</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
                        {data.map((category) => (
                          <tr key={category.contactusid}>
						  <td>{category.name}</td>
                            <td>{category.email}</td>
                            <td>{category.subject}</td>
                            <td>{category.number}</td>
                            <td>{category.message}</td>
                            <td>
                            <div className="btn-group">
                                {category.contacted ? (
                                   <button
                                   className="btn btn-outline-success"
                                   disabled
                                 >
                                   Completed
                                 </button>
                                ) : (
                                  <a
                                    href="#"
                                    className="btn btn-outline-success"
                                    onClick={() =>
                                      handleFinishButtonClick(
                                        category.contactusid
                                      )
                                    }
                                  >
                                    Finish
                                  </a>
                                )}
                              </div>         </td>
                          </tr>
                        ))}
                      </tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> 
			</div> 


		</div> 

	</div> 
  
    );
};

export default CompanyInfo;
