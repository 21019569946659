// EmployeeLogin.js
import React, { useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import _ from 'lodash';
import './App.css';
import { Form, Input, Button, Row, Col } from 'antd';
import axios from 'axios';

const EmployeeLogin = () => {
    const [formData, setFormData] = useState({
        e_UserName: '',
        e_Password: ''
    });

    const navigate = useNavigate();

    // Replace this with actual logic to retrieve the token, if necessary
    const token = 'your-token-here'; 

    const handleLogin = async () => {
        const clonedFormData = _.cloneDeep(formData);

        try {
            const response = await axios.post('https://api.tomorrowlandclub.com/employees/addemp', {
                e_UserName: clonedFormData.e_UserName,
                e_Password: clonedFormData.e_Password,
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            });

            // Redirect to CustomerRegistration on successful login
            navigate('/customer');
        } catch (error) {
            console.error('Error logging in employee:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <Row justify="center" align="middle" style={{ height: '100vh' }}>
            <Col>
                <div className='reg-wrapper'>
                    <div className='reg-title'>
                        <h4 className='reg-cap'>EMPLOYEE LOGIN</h4>
                    </div>
                    <Form name='login' className='login' initialValues={{ remember: true }} onFinish={handleLogin}>
                        <Form.Item name='e_UserName' rules={[{ required: true, message: 'Please input your username!' }]}>
                            <Input placeholder='User Name' name='e_UserName' value={formData.e_UserName} onChange={handleChange} />
                        </Form.Item>
                        <Form.Item name='e_Password' rules={[{ required: true, message: 'Please input your password!' }]}>
                            <Input placeholder='Password' name='e_Password' value={formData.e_Password} onChange={handleChange} type='password' />
                        </Form.Item>
                        <Form.Item>
                            <Button type='primary' htmlType='submit' className='reg-submit'>
                                LOGIN
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
        </Row>
    )
}

export default EmployeeLogin;
