import React, { useState } from 'react';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

const Influencer = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mediaPlatform, setMediaPlatform] = useState('');
  const [mobileNO, setMobileNO] = useState('');
  const [regDate, setRegDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [inf_Id, setInfId] = useState('');
  const [url, setUrl] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedDate = regDate ? new Date(regDate).toISOString().split('T')[0] : null;
      setLoading(true);

      const response = await fetch("https://api.tomorrowlandclub.com/influencers/add", {
        method: "POST",
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          inf_Name: name,
          inf_EmailId: email,
          mediaPlatform: mediaPlatform,
          url:'https://api.tomorrowlandclub.com',
          mobileNO: mobileNO,
          reg_Date: formattedDate,
        }),
      });

      if (response.status === 401 || response.status === 404) {
        navigate('/AdminLogin');
        return;
      }

      if (response.ok) {
        const data = await response.json();
        console.log("POST request successful");
        setUrl(response.data.url);
        if (data && data.length > 0) {
          setData(data);
        } else {
          setData([]);
        }
        setLoading(false);
      } else {
        console.error("POST request failed");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setLoading(false);
    }
  };

  return (
    <div className='wrapper'>
      <SideBar />
      <div className='ec-page-wrapper'>
        <Header />
        <div className="ec-content-wrapper">
          <div className="content" style={{ marginTop: "50px" }}>
            <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
              <h1>Influencer</h1>
              <p className="breadcrumbs">
                <span><a href="index">Home</a></span>
                <span><i className="mdi mdi-chevron-right"></i></span>Influencer
              </p>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-12">
                <div className="ec-cat-list card card-default mb-24px">
                  <div className="card-body">
                    <div className="ec-cat-form">
                      <h4>Add Influencer</h4>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group row">
                          <label htmlFor="name" className="col-12 col-form-label">Name</label>
                          <div className="col-12">
                            <input id="name" name="name" className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="email" className="col-12 col-form-label">Email</label>
                          <div className="col-12">
                            <input id="email" name="email" className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="mediaPlatform" className="col-12 col-form-label">Media Platform</label>
                          <div className="col-12">
                            <input id="mediaPlatform" name="mediaPlatform" className="form-control" type="text" value={mediaPlatform} onChange={(e) => setMediaPlatform(e.target.value)} autoComplete="off" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="mobileNO" className="col-12 col-form-label">Mobile Number</label>
                          <div className="col-12">
                            <input id="mobileNO" name="mobileNO" className="form-control" type="text" value={mobileNO} onChange={(e) => setMobileNO(e.target.value)} autoComplete="off" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="regDate" className="col-12 col-form-label">Registration Date</label>
                          <div className="col-12">
                            <input id="regDate" name="regDate" className="form-control" type="date" value={regDate} onChange={(e) => setRegDate(e.target.value)} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <button name="submit" type="submit" className="btn btn-primary">
                              {loading ? 'Submitting...' : 'Submit'}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12">
                <div className="ec-cat-list card card-default">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table id="responsive-data-table" className="table">
                        <thead>
                          <tr>
                            <th>Influencer Name</th>
                            <th>Email</th>
                            <th>Media Platform</th>
                            <th>Mobile Number</th>
                            <th>Registration Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, index) => (
                            <tr key={index}>
                              <td>{item.inf_Name}</td>
                              <td>{item.inf_EmailId}</td>
                              <td>{item.mediaPlatform}</td>
                              <td>{item.mobileNO}</td>
                              <td>{item.reg_Date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Influencer;
