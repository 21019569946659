import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table, Checkbox, Modal, InputNumber } from 'antd';
import { useNavigate } from 'react-router-dom';
import EditPincode from "../../src/pincodes/editPincode"

import axios from 'axios'; 
import "../style.scss";
import "../ekka.scss";
import SideBar from "../sidebar";
import Header from "../Header";
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
  
    useEffect(() => {
      if (editing && inputRef.current) {
        inputRef.current.focus();
      }
    }, [editing]);
  
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
  
    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };
  
    let childNode = children;
  
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'checkbox' ? (
            <Checkbox ref={inputRef} onClick={save} />
          ) : (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          )}
        </Form.Item>
      ) : dataIndex === 'checkbox' ? (
        <Checkbox onClick={toggleEdit} />
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
  
    return <td {...restProps}>{childNode}</td>;
  };
  
const DeliveryCharges = () => {
    const navigate = useNavigate();
  const [count, setCount] = useState(2);
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [shippingRate, setShippingRate] = useState(0);
  const [cartValue, setCartValue] = useState(0);
const [editingPincode, setEditingPincode] = useState(null);
const handleEditPincode = (record) => {
  setEditingPincode(record);
  setEditModalVisible(true);
};
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };
  const handleCheckboxChange = (pincodeid) => {
    // Toggle the value in sessionStorage
    const selectedPincodes = JSON.parse(sessionStorage.getItem('deliveryPincodes')) || [];
    const index = selectedPincodes.indexOf(pincodeid);

    if (index !== -1) {
      selectedPincodes.splice(index, 1);
    } else {
      selectedPincodes.push(pincodeid);
    }

    sessionStorage.setItem('deliveryPincodes', JSON.stringify(selectedPincodes));
  };
  
  const defaultColumns = [
    {
      title: '',
      dataIndex: 'checkbox',
      render: (_, record) => (
        <Checkbox
          onClick={() => handleCheckboxChange(record.pincodeid)} // handleCheckboxChange function added
        />
      ),
    },
    
    // {
    //   title: 'Pincode ID',
    //   dataIndex: 'pincodeid',
    //   width: '30%',
    //   editable: false, // Assuming 'pincodeid' is not editable
    // },
    {
      title: 'Pincode',
      dataIndex: 'pincode',
      editable: false,
    },
    // {
    //   title: 'Area',
    //   dataIndex: 'area',
    //   editable: true,
    // },
    {
      title: 'State',
      dataIndex: 'state',
      editable: false,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      editable: false,
    },
    // {
    //   title: 'Shipping Rate',
    //   dataIndex: 'shippingrate',
    //   editable: true,
    // },
    // {
    //   title: 'Cart Value',
    //   dataIndex: 'cartvalue',
    //   editable: true,
    // },
    // {
    //   title: 'Operation',
    //   dataIndex: 'operation',
    //   render: (_, record) =>
    //     data.length >= 1 ? (
    //       <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
    //         <a>Delete</a>
    //       </Popconfirm>
    //     ) : null,
    // },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record) => (
        <td>
          <div className="btn-group">
            <a
              href="#"
              className="btn btn-outline-success"
              onClick={(e) => {
                e.preventDefault();
                handleEditPincode(record);
              }}
            >
              Edit
            </a>
            
          </div>
        </td>
      ),
    },
  ];
  
  const handleAdd = () => {
    const newData = {
      key: count,
      name: `Edward King ${count}`,
      age: '32',
      address: `London, Park Lane no. ${count}`,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  useEffect(() => {
    const fetchPincodeData = async () => {
      try {
        const token = localStorage.getItem('token'); // Assuming you store your token in localStorage
        if (!token) {
          // Redirect to the login page if the token is not present
          navigate('/login');
          return;
        }

        const response = await axios.put("https://api.shopeasey.com/api/v1/pincode/get", {}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 401 || response.status === 404) {
          // Redirect to the login page for 401 or 404 errors
          navigate('/login');
          return; // Exit early to prevent further logic execution
        }

        setData(response.data);
        sessionStorage.removeItem('deliveryPincodes');
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPincodeData();
  }, [navigate]);
  const handleMakeAvailable = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const selectedPincodes = JSON.parse(sessionStorage.getItem('selectedPincodes')) || [];

      if (selectedPincodes.length === 0) {
        // No pincodes selected, show an alert or handle accordingly
        alert('Please select pincodes before making them available.');
        return;
      }

      // Create FormData
      const formData = new FormData();
        formData.append(`pincodeid`, selectedPincodes);

      const response = await axios.post(
        'https://api.shopeasey.com/api/v1/pincode/makeavailable',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      // Handle the API response as needed
      console.log('Make Available API Response:', response.data);

      // Clear selected pincodes from sessionStorage after making them available
      sessionStorage.removeItem('selectedPincodes');

      // You may also want to refresh the data after making pincodes available
      // Call fetchPincodeData or any relevant function here

    } catch (error) {
      console.error('Error making pincodes available:', error);
    }
  };
  // const handleMakeChangesAvailable = async () => {
  //   try {
  //     const token = localStorage.getItem('token');
  //     if (!token) {
  //       navigate('/login');
  //       return;
  //     }

  //     const selectedPincodes = JSON.parse(sessionStorage.getItem('availabledPincodes')) || [];

  //     if (selectedPincodes.length === 0) {
  //       // No pincodes selected, show an alert or handle accordingly
  //       alert('Please select pincodes before making them available.');
  //       return;
  //     }

  //     // Create FormData
  //     const formData = new FormData();
  //       formData.append(`pincodeid`, selectedPincodes);

  //     const response = await axios.post(
  //       'https://api.shopeasey.com/api/v1/pincode/editallById',
  //       formData,
  //       {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //           'Authorization': `Bearer ${token}`, 
  //         },
  //       }
  //     );

  //     // Handle the API response as needed
  //     console.log('Make Available API Response:', response.data);

  //     // Clear selected pincodes from sessionStorage after making them available
  //     sessionStorage.removeItem('availabledPincodes');
  //     // You may also want to refresh the data after making pincodes available
  //     // Call fetchPincodeData or any relevant function here

  //   } catch (error) {
  //     console.error('Error making pincodes available:', error);
  //   }
  // };
  
  const handleMakeChangesClick = () => {
    setModalVisible(true);
  };
  const handleOk = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const selectedPincodes = JSON.parse(sessionStorage.getItem('deliveryPincodes')) || [];

      if (selectedPincodes.length === 0) {
        alert('Please select pincodes before making changes.');
        return;
      }

      const formData = new FormData();
      formData.append('shippingrate', shippingRate);
      formData.append('cartvalue', cartValue);
      formData.append('pincodeid', selectedPincodes);

      const response = await axios.post(
        'https://api.shopeasey.com/api/v1/pincode/editallById',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      console.log('Make Changes API Response:', response.data);

      // Clear selected pincodes from sessionStorage after making changes
      sessionStorage.removeItem('deliveryPincodes');
      window.location.reload();
      // You may also want to refresh the data after making changes
      // Call fetchPincodeData or any relevant function here

      setModalVisible(false);
    } catch (error) {
      console.error('Error making changes:', error);
    }
  };
  const handleCancel = () => {
    // Handle the logic when the user clicks "Cancel" in the modal
    setModalVisible(false);
  };
  return (
    <div>
        <div className="wrapper">
        <SideBar />
      <Header />
      <div className="ec-content-wrapper">
        <div className="content" style={{ marginTop: "50px" }}>
        <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
        <h1>Delivery Charges</h1>
            <p className="breadcrumbs">
            <button onClick={handleMakeChangesClick}>Make Changes</button>
            </p>
          </div>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={data}
        columns={columns}
      />
    </div>
    </div>
    </div>
    <EditPincode
  category={editingPincode}
  show={editModalVisible}
  onHide={() => setEditModalVisible(false)}
  onSave={handleSave} // Implement the handleSave function to update your data
/>
<Modal
        title="Make Changes"
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <label>Shipping Rate:</label>
          <InputNumber
            value={shippingRate}
            onChange={(value) => setShippingRate(value)}
          />
        </div>
        <div>
          <label>Cart Value:</label>
          <InputNumber
            value={cartValue}
            onChange={(value) => setCartValue(value)}
          />
        </div>
      </Modal>
    </div>
  );
};
export default DeliveryCharges;