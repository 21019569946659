	import React, { useEffect, useState } from "react";
	import { DatePicker } from 'antd';
	import '../style.scss';
	import '../ekka.scss';
	import SideBar from '../sidebar';
	import Header from '../Header';
	import axios from "axios";
	import moment from 'moment';
	const OrderDetail = (orderid) => {
		const token = localStorage.getItem('token');
		const [orderData, setOrderData] = useState(null);
		const [orderStatus, setOrderStatus] = useState("");
		const currentDate = moment();
		const getOrderDetails = async () => {
			const selectedID = localStorage.getItem("selectedID");
			try{
			const payload = {
				orderid:selectedID,
			}
			const response = await axios.post("https://api.shopeasey.com/api/v1/order/getbyid",payload,{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if(response.status === 200){
				const dataOrder = response.data;
				setOrderData(dataOrder);
			}
			else{
				console.error("Failed to fetch order details");
			}
		} catch(error) {
			console.error("Error fetching Order details:", error);
		}
		};
		useEffect(() => {
			getOrderDetails();
		}, []);
		const handleStatusChange = async (newStatus) => {
			try {
			const payload = {
				orderid: orderData.orderid,
				orderStatus: newStatus,
			};
		
			const response = await axios.post(
				"https://api.shopeasey.com/api/v1/order/editstatus",
				payload,
				{
				headers: {
					Authorization: `Bearer ${token}`,
				},
				}
			);
		
			if (response.status === 200) {
				console.log("Order status updated successfully");
				// You might want to update the orderData state with the new data received from the server
				// For example, call getOrderDetails again after updating the status
				getOrderDetails();
			} else {
				console.error("Failed to update order status");
			}
			} catch (error) {
			console.error("Error updating Order status:", error);
			}
		};
		
		useEffect(() => {
			getOrderDetails();
		}, []);

	return(
	<div class="wrapper">
	<SideBar/>
	<Header/>
	<div class="ec-content-wrapper">
					<div class="content">
						<div class="breadcrumb-wrapper breadcrumb-wrapper-2">
							<h1>Order Detail</h1>
							<p class="breadcrumbs"><span><a href="index.html">Home</a></span>
								<span><i class="mdi mdi-chevron-right"></i></span>Orders
							</p>
						</div>
						{orderData ? (
						<div class="row">
							<div class="card mt-4 trk-order">
									<div class="p-4 text-center text-white text-lg bg-dark rounded-top">
										<span class="text-uppercase">Tracking Order No - </span>
										<span class="text-medium">{orderData.ordernumber}</span>
									</div>
									<div
										class="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2 bg-secondary">
										<div class="w-100 text-center py-1 px-2"><span class="text-medium">Shipped
												Via:</span> UPS Ground</div>
										<div class="w-100 text-center py-1 px-2"><span class="text-medium">Status:</span>
											{orderData.orderStatus}</div>
										<div class="w-100 text-center py-1 px-2"><span class="text-medium">Expected
												Date:</span>  <DatePicker defaultValue={currentDate} /></div>
									</div>
									<div class="card-body">
										<div
											class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
											<div class="step completed">
												<div class="step-icon-wrap">
													<div class="step-icon"><i class="mdi mdi-cart"></i></div>
												</div>
												<h4 class="step-title">Confirmed Order</h4>
											</div>
											<div class="step completed">
												<div class="step-icon-wrap">
													<div class="step-icon"><i class="mdi mdi-tumblr-reblog"></i></div>
												</div>
												<h4 class="step-title">Processing Order</h4>
											</div>
											<div class="step completed">
												<div class="step-icon-wrap">
													<div class="step-icon"><i class="mdi mdi-gift"></i></div>
												</div>
												<h4 class="step-title">Product Dispatched</h4>
											</div>
											<div class="step">
												<div class="step-icon-wrap">
													<div class="step-icon"><i class="mdi mdi-truck-delivery"></i></div>
												</div>
												<h4 class="step-title">On Delivery</h4>
											</div>
											<div class="step">
												<div class="step-icon-wrap">
													<div class="step-icon"><i class="mdi mdi-hail"></i></div>
												</div>
												<h4 class="step-title">Product Delivered</h4>
											</div>
										</div>
									</div>
								</div>
							<div class="col-12">
								<div class="ec-odr-dtl card card-default">
									<div class="card-header card-header-border-bottom d-flex ">
										<h2 class="ec-odr">Order Detail<br/>
											<span class="small justify-content-right col-7">Order ID: #{orderData.orderid}</span>
											<span class="col-2 small">
											<select
          id="statusDropdown"
          onChange={(e) => {
            const newStatus = e.target.value;
            setOrderStatus(newStatus);
            handleStatusChange(newStatus); // Pass the newStatus to handleStatusChange
          }}
        >
			<option value="Placed">Placed</option>
			<option value="OrderConfirmed">Order Confirmed</option>
			<option value="Cancelled">Cancelled</option>
			<option value="OutForDelivery">Out For Delivery</option>
			<option value="Delivered">Delivered</option>
		</select></span>
										</h2>
									</div>
									
									<div class="card-body">
										<div class="row">
											<div class="col-xl-3 col-lg-6">
												<address class="info-grid">
													<div class="info-title"><strong>Billing Address:</strong></div><br/>
													<div class="info-content">
													{orderData.billingaddress}
													</div>
												</address>
											</div>
											<div class="col-xl-3 col-lg-6">
												<address class="info-grid">
													<div class="info-title"><strong>Shipped Address:</strong></div><br/>
													<div class="info-content">
													{orderData.shippingaddress.name}<br/>
													{orderData.shippingaddress.address1},{orderData.shippingaddress.address2}<br/>
													{orderData.shippingaddress.city},{orderData.shippingaddress.state},{orderData.shippingaddress.country}<br/>
													{orderData.shippingaddress.pincode}<br/>
													{orderData.shippingaddress.phonenumber}
													</div>
												</address>
											</div>
											<div class="col-xl-3 col-lg-6">
												<address class="info-grid">
													<div class="info-title"><strong>Payment Method:</strong></div><br/>
													<div class="info-content">
														{orderData.paymentmethod==="cod"? "Cash On Delivery":orderData.paymentmethod}
													</div>
												</address>
											</div>
											<div className="col-xl-3 col-lg-6">
  <address className="info-grid">
    <div className="info-title"><strong>Order Date:</strong></div><br />
    <div className="info-content">
      {orderData.createDate && (
        <div>
          <p>{new Date(orderData.createDate).toLocaleDateString()}</p>
          <p>{new Date(orderData.createDate).toLocaleTimeString()}</p>
        </div>
      )}
    </div>
  </address>
</div>

										</div>
										<div class="row">
											<div class="col-md-12">
												<h3 class="tbl-title">PRODUCT SUMMARY</h3>
												<div class="table-responsive">
													<table class="table table-striped o-tbl">
														<thead>
															<tr class="line">
																<td><strong>#</strong></td>
																<td class="text-center"><strong>IMAGE</strong></td>
																<td class="text-center"><strong>PRODUCT</strong></td>
																<td class="text-center"><strong>PRICE/UNIT</strong></td>
																<td class="text-right"><strong>QUANTITY</strong></td>
																<td class="text-right"><strong>SUBTOTAL</strong></td>
															</tr>
														</thead>
														<tbody>
														{orderData.products.map((product, index) => (
															<tr key={index}>
																<td>{index + 1}</td>
																<td><img class="product-img"
																		src={product.productimage} alt="" /></td>
																<td><strong>{product.productname}</strong><br/>
																<td dangerouslySetInnerHTML={{ __html: product.sortdescription }}></td></td>
																<td class="text-center">{product.sellingprice}</td>
																<td class="text-center">{product.productcount}</td>
																<td class="text-right">{orderData.subtotal}</td>
															</tr>
														))}
															{/* <tr>
																<td colspan="4"></td>
																<td class="text-right"><strong>Taxes</strong></td>
																<td class="text-right"><strong>N/A</strong></td>
															</tr> */}
															<tr>
																<td colspan="4">
																</td>
																<td class="text-right"><strong>Total</strong></td>
																<td class="text-right"><strong>{orderData.total}</strong></td>
															</tr>

															<tr>
																<td colspan="4">
																</td>
																<td class="text-right"><strong>Payment Status</strong></td>
																<td class="text-right"><strong>{orderData.paymentstatus}</strong></td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
						) : (
							// Render a loading state or handle the absence of data
							<p>Loading...</p>
						)}
					</div>
				</div> 

	</div>
										
		);
	}
	export default OrderDetail;