import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Table, Input, Button, Select} from "antd"; // Import Input and Button for filtering
import '@ant-design/cssinjs';
import '../style.scss';
import '../ekka.scss';
import SideBar from '../sidebar';
import Header from '../Header';

const { Search } = Input;
const { Option } = Select;

const NewOrder = () => {
  const token = localStorage.getItem('token');
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]); // State for filtered data
  const [searchText, setSearchText] = useState('');
  const storeOrderIDInSession = (orderid) => {
    sessionStorage.setItem('selectedOrderID', orderid);
  };
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.post("https://api.shopeasey.com/api/v1/order/getall", {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const data = response.data;
          setOrders(data);
          const orderIdsArray = data.map(order => order.orderid);
        localStorage.setItem('orderIds', JSON.stringify(orderIdsArray));
          setFilteredOrders(data); // Initialize filtered data with all orders
        } else {
          console.error("Failed to fetch orders");
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    fetchOrders();
  }, [token]);
  const handleSearch = (value) => {
    setSearchText(value);
    const filteredData = orders.filter(order => order.orderid.toString().toLowerCase().includes(value.toLowerCase()));
    setFilteredOrders(filteredData);
  };

  const handleViewClick = (orderID) => {
    const orderIdsArray = JSON.parse(localStorage.getItem('orderIds'));
  const selectedOrderIndex = orderIdsArray.indexOf(orderID);
  if (selectedOrderIndex !== -1) {
    localStorage.setItem('selectedID', orderID);
  } else {
    console.error("OrderID not found in orderIdsArray");
  }
  };
  // Define the columns for the DataTable
  const columns = [
    {
      title: "ID",
      dataIndex: "orderid",
      key: "orderid",
      // Add a filter input to the column
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder="Search ID"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => record.orderid.toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
			setSearchText(searchText); 
        }
      },
      render: (text) => text,
    },
    {
      title: "Customer",
      dataIndex: ["shippingaddress", "name"],
      key: "shippingaddress.name",
    },
	{
		title: "Items",
		dataIndex: "products",
		key: "itemsCount",
		render: (products) => {
		  const totalProducts = products.reduce((total, product) => total + parseInt(product.productcount || 0, 10), 0);
		  return totalProducts;
		},
	  },	  
    {
      title: "Price",
      dataIndex: "products",
      key: "price",
      render: (products) =>
        products.reduce((acc, product) => acc + parseFloat(product.productprice || 0), 0).toFixed(2),
    },
    {
      title: "Payment",
      dataIndex: "paymentmethod",
      key: "paymentmethod",
    },
    {
		title: "Status",
		dataIndex: "orderStatus",
		key: "orderStatus",
		render: (text, record) => (
		  <Select defaultValue={text} style={{ width: 120 }}>
			<Option value="Placed">Placed</Option>
			<Option value="OrderConfirmed">Order Confirmed</Option>
			<Option value="Cancelled"> Cancelled</Option>
			<Option value="OutForDelivery"> Out For Delivery</Option>
			<Option value="Delivered"> Delivered</Option>
		  </Select>
		),
	  },
    {
      title: "Date",
      dataIndex: "createDate",
      key: "createDate",
      render: (text, record) => {
        const date = new Date(record.createDate);
        return date.toISOString().split('T')[0];
      },
    },
    
	{
		title: "Actions",
		key: "actions",
		render: (text, record) => (
		  <div className="btn-group mb-1">
			<Link to={`/order-detail`} onClick={() => handleViewClick(record.orderid)}>View</Link> {/* Include the orderid as a URL parameter */}
		  </div>
		),
	  },
	  
	  
  ];

  return (
    <div className="wrapper">
      <SideBar />
      <Header />
      <div className="ec-content-wrapper">
        <div className="content" style={{ marginTop: "50px" }}>
          <div className="breadcrumb-wrapper breadcrumb-wrapper-2">
            <h1>New Orders</h1>
          </div>
          <Search
            placeholder="Search by ID"
            onSearch={handleSearch}
            style={{ width: 200, marginBottom: 16 }}
          />
          <Table dataSource={filteredOrders} columns={columns} />
        </div>
      </div>
    </div>
  );
}

export default NewOrder;
