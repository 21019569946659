// EditPincode.js

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const EditPincode = ({
  category,
  show,
  onHide,
  onSave,
  isLoadingEdit,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Initialize state variables with category values or default values
  const [country, setCountry] = useState(category?.country || '');
  const [state, setState] = useState(category?.state || '');
  const [pincode, setPincode] = useState(category?.pincode || '');
  const [area, setArea] = useState(category?.area || '');
  const [shippingrate, setShippingrate] = useState(category?.shippingrate || '');
  const [cartvalue, setCartvalue] = useState(category?.cartvalue || '');

  const handleSaveChanges = async () => {
    try {
      setLoading(true);

      const updatedPincode = {
        pincodeid: category?.pincodeid, // Include the category ID for identification
        pincode,
        area,
        state,
        country,
        shippingrate,
        cartvalue,
      };

      const response = await axios.post(
        'https://api.shopeasey.com/api/v1/pincode/edit',
        updatedPincode,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate('/AdminLogin');
        return;
      }

      if (response.data.success) {
        console.log('Pincode updated successfully');
        onSave(updatedPincode); // Notify the parent component about the update
        onHide(); // Close the modal
      } else {
        console.error('Failed to update Pincode');
      }

      setLoading(false);
      // You might not need to reload the window; check if it's necessary
      // window.location.reload(); // Refresh the page
    } catch (error) {
      console.error('Error updating Pincode:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Set form fields based on the category prop when it changes
    setCountry(category?.country || '');
    setState(category?.state || '');
    setPincode(category?.pincode || '');
    setArea(category?.area || '');
    setShippingrate(category?.shippingrate || '');
    setCartvalue(category?.cartvalue || '');
  }, [category]);

  return (
    <Modal show={show} onHide={onHide}>
    {/* Modal content */}
    <Modal.Header closeButton>
      <Modal.Title>Edit Pincode</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group controlId="categoryname">
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            name="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="slug">
          <Form.Label>State</Form.Label>
          <Form.Control
            type="text"
            name="state"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="sortdescription">
          <Form.Label>Pincode</Form.Label>
          <Form.Control
          type='text'
            name="pincode"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="fulldescription">
          <Form.Label>Area</Form.Label>
          <Form.Control
          type='text'
            name="area"
            value={area}
            onChange={(e) => setArea(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="producttag">
          <Form.Label>Shipping Rate</Form.Label>
          <Form.Control
            type="text"
            name="shippingrate"
            value={shippingrate}
            onChange={(e) => setShippingrate(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="cartvalue">
          <Form.Label>Cart Value</Form.Label>
          <Form.Control
            type="text"
            name="cartvalue"
            value={cartvalue}
            onChange={(e) => setCartvalue(e.target.value)}
          />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Close
      </Button>
      <Button variant="primary" onClick={handleSaveChanges} disabled={loading}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default EditPincode;
