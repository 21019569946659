// Routes.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminLogin from './AdminLogin'; 
import MainCategory from "./MainCategory";

import ProductDetail from './ProductDetail';
import ListProduct from './ListProduct';
import ProductGrid from './ProductGrid';
import ProductAdd from './ProductAdd';
import ProductEdit from '../src/ProductEdit';
import SubSubCategory from './SubsubCategory';
import Banner from './Banner';
import Offer from './offer';
import CommonInfo from "./CommonInfo";
import ClientDetails from "./ClientDetails";
import CompanyInfo from "./CompanyInfo";
import TermsConditions from "./TermsConditions";
import NewOrder from './order/NewOrder';
import OrderDetail from './order/OrderDetail';
import EditTerms from "../src/otherpages/EditTerms";
import AboutUs from "./AboutUs";
import EditAbout from "../src/otherpages/EditAbout";
import PrivacyPolicy from "../src/otherpages/Privacy";
import EditPrivacy from "../src/otherpages/EditPrivacy";
import CustomerService from '../src/otherpages/customerservice';
import EditCustomer from "../src/otherpages/EditCustomer";
import AvailablePincodes from "../src/pincodes/index";
import ShowPincodes from "../src/pincodes/showpincodes";
import DeliveryCharges from "../src/deliverycharges/index";
import Events from './Events';
import Booking from './Booking';
import Influencer from './Influencer';
import EmployeeLogin from './EmployeeLogin';
import Employee from './Employee';
import Customer from './Customer';
export default function AppRoutes() {
  return (
    <Router>
      <Routes>
      <Route index element={<AdminLogin />} />
      <Route path="/AdminLogin" element={<AdminLogin />} />
      <Route path="/employeelogin" element={<EmployeeLogin />} />
      <Route path="/employee" element={<Employee />} />
      <Route path="/customer" element={<Customer />} />
      <Route path="/main-banner" element={<Banner />} />
      <Route path="/main-offer" element={<Offer />} />
        <Route path="/main-category" element={<MainCategory />} />
        <Route path="/events" element={<Events />} />
        <Route path="/Booking" element={<Booking />} />
        <Route path="/influencer" element={<Influencer />} />
        <Route path="/product-detail" element={<ProductDetail />} />
        <Route path="/product-list" element={<ListProduct />} />
        <Route path="/product-grid" element={<ProductGrid />} />
        <Route path="/product-add" element={<ProductAdd />} />
        <Route path="/product-edit" element={<ProductEdit />} />
        <Route path="/banner" element={<SubSubCategory />} />
        <Route path="/main-common" element={<CommonInfo />} />
        <Route path="/main-company" element={<CompanyInfo />} />
        <Route path="/clientDetails" element={<ClientDetails />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/new-order" element={<NewOrder />} />
        <Route path="/order-detail" element={<OrderDetail />} />
        <Route path="/EditTerms" element={<EditTerms />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/EditAbout" element={<EditAbout />} />
        <Route path="/byelaw" element={<PrivacyPolicy />} />
        <Route path="/EditPrivacy" element={<EditPrivacy />} />
        <Route path="customer-service" element={<CustomerService />} />
        <Route path="/EditCustomer" element={<EditCustomer />} />
        <Route path="/main-pincodes" element={<AvailablePincodes />} />
        <Route path="/main-pincode" element={<ShowPincodes />} />
        <Route path="/delivery-charges" element={<DeliveryCharges />} />
      </Routes>
    </Router>
  );
}


