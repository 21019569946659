import React, { useState, useEffect   } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import "./App.css";
import logoUrl from './img/logo.png';

const AdminLogin = () => {
    const [mobile, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const token = "";
    
    const handleLogin = async () => {
    
        try {
          const response = await fetch('https://api.nagaratharcoc.com/api/v1/login/authenticateadmin', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              
            },
            body: JSON.stringify({ mobile, password }),
          });
          if (response.ok) {
            const data = await response.json();
            
          localStorage.setItem("token",data.token);
            navigate('../events');
          } else {
            console.error('Admin Login failed');
          }
        } catch (error) {
          console.error('Error logging in:', error);
        }
      };
    
      useEffect(() => {
        const fetchLogo = async () => {
          try {
            const response = await fetch('https://api.shopeasey.com/api/v1/logo/get',{
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                
              },
            });
            if (response.ok) {
              const data = await response.text();
              // setLogoUrl(data); // Update the logo URL in the state
              sessionStorage.setItem("logoUrl", data);
            } else {
              console.error('Failed to fetch logo');
            }
          } catch (error) {
            console.error('Error fetching logo:', error);
          }
        };
    
        fetchLogo();
      }, []); 
  return (
    <div className="accountbg">
      <div className="login-center">
        <div className="login-wrappere">
          <div className="test">
        <img src={logoUrl} alt="Logo" width='100%'/>
            <h4 className="log_cap">Sign in to continue to Login.</h4>
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleLogin}
          >
            <Form.Item
              name="userName"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                value={mobile}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                className="login-form-button"
                style={{ width: "100%" }}
              >
                Log in
              </Button>
            </Form.Item>

            {/* <Form.Item>
              <Link to="#" className="login-form-forgot">
                Forgot password
              </Link>
            </Form.Item> */}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
