import React, { useState, useEffect } from 'react';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Input, Form, Upload, Button, DatePicker, notification } from 'antd';
import axios from "axios";
import { UploadOutlined } from '@ant-design/icons';
import EditSubCategoryModal from './EditSubCategory';
import moment from 'moment';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const qs = require('qs');


const Events = () => {

	const token = localStorage.getItem('token');
	const [reloadList, setreloadList] = useState(false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [fileid, setUploadfiles] = useState([]);
	const [eventbanner, setEventbanner] = useState([]);
	const [fileList, setFileList] = useState([]); // To store uploaded files
	const [fileList2, setFileList2] = useState([]);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [eventlink, setEventlink] = useState('');
	const [date, setDate] = useState('');
	const [categoryList, setCategoriesList] = useState([]);
	const [selectedCategoryId, setSelectedCategoryId] = useState('');

	const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
	const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
	const [uploadFiles, setuploadfiles] = useState([]);

	const setreloadListnow = () => {
		setreloadList(true);
	};
	const handleEditCategory = (category) => {
		function getAllFileIds(category) {
			// Check if the 'files' property exists in the category object
			if (category && category.files && Array.isArray(category.files)) {
				// Map over the array and extract the fileId property
				return category.files.map(file => file.fileid);
			} else {
				// Handle the case where 'files' property is missing or not an array
				console.error("Invalid category object or missing 'files' property.");
				return [];
			}
		}
		let fileIds = getAllFileIds(category);
		sessionStorage.setItem('fileids', JSON.stringify(fileIds));
		setSelectedCategory(category);
		setShowEditModal(true);
	};
	const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	};
	const handleDeleteCategory = async (eventId) => {
		try {
			const confirmDelete = window.confirm("Are you sure you want to delete this event?");

			if (!confirmDelete) {
				// If the user cancels, do nothing
				return;
			}
			setLoading(true);
			const response = await axios.delete(
				'https://api.tomorrowlandclub.com/events/delete',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					data: qs.stringify({ eventid: eventId })
				}
			);



			// Check if the deletion was successful
			if (response.status === 200) {
				// The category was deleted successfully
				console.log('Category deleted successfully');

				// Reload the page to reflect the changes
				// window.location.reload();
				setreloadList(true);
			} else {
				console.error('Failed to delete category');
			}

			setLoading(false);
		} catch (error) {
			console.error('Error deleting category:', error);
			setLoading(false);
		}
	};


	const handleSaveEdit = async (editedData) => {
		try {
			setLoading(true);
			editedData.eventid = selectedCategory.eventId;
			delete editedData.files;
			// Make an API request to update the subcategory data
			const response = await axios.put(
				"https://api.tomorrowlandclub.com/events/updateEvent",
				editedData,
				{
					headers: {
						Authorization: 'Bearer ' + token,
						'Content-Type': 'application/x-www-form-urlencoded',

						'Access-Control-Allow-Origin': '*',
					},
				}
			);

			if (response.status === 401 || response.status === 404) {
				// Redirect to the login page for 401 or 404 errors
				navigate('/AdminLogin');
				return;
			}

			if (response.status === 200) {
				console.log('Subcategory updated successfully');
				window.location.reload();
				// Optionally, you can update your component state or perform other actions here
			} else {
				console.error('PUT request failed');
			}
		} catch (error) {
			console.error('Error updating data:', error);
		} finally {
			setLoading(false);
			handleCloseEditModal(); // Close the modal whether the request succeeds or fails
		}
	};

	useEffect(() => {
		// if (!token) {
		//   // Redirect to the login page if the token is not present
		//   navigate('/AdminLogin');
		//   return;
		// }
		fetchData();
	}, [reloadList]);

	const fetchData = async () => {
		try {
			const response = await axios.get("https://api.tomorrowlandclub.com/events/all", {}, {

				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			});

			setreloadList(false);
			if (response.status === 401 || response.status === 404) {
				// Redirect to the login page for 401 or 404 errors
				navigate('/AdminLogin');
				return; // Exit early to prevent further logic execution
			}
			setData(response.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const onFinish = async (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		const token = localStorage.getItem('token');
		try {

			const fileIdsArray = Array.isArray(fileid) ? fileid : [fileid];
			const formattedDate = date ? date.format('YYYY/MM/DD') : null;
			console.log(formattedDate);
			setLoading(true);
			// TODO: Replace with actual API call
			const response = await fetch("https://api.tomorrowlandclub.com/events/process", {
				method: "POST",
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				body: JSON.stringify({
					eventName: title,
					description,
					bannerlink: eventbanner,
					eventlink,
					fileIds: fileIdsArray,
					date: formattedDate,
				}),
			});

			if (response.status === 401 || response.status === 404) {
				// Redirect to the login page for 401 or 404 errors
				navigate('/login');
				return; // Exit early to prevent further logic execution
			}

			if (response.ok) {
				const data = await response.json();
				const token = data.token;
				console.log("POST request successful");
				if (response.data && response.data.length > 0) {
					setData(response.data);
				} else {
					setData([]);
				}
				setLoading(false);
				localStorage.removeItem('organizerIds');
				// window.location.reload(); // Refresh the page
				fetchData();
				if (data.token) {
					localStorage.setItem('token', token);
				}
			} else {
				console.error("POST request failed");
			}
		} catch (error) {
			console.error("Error posting data:", error);
			setLoading(false);
		}
	};
	const handleFileUpload = async (options) => {
		console.log('options', options);
		const { onSuccess, onError, file, onProgress } = options;
		// const maxFileSize = 1024 * 1024;
		// if (file.size > maxFileSize) {
		// 	notification.error({
		// 		message: 'Please upload an image with a size less than 1MB.',
		// 	});
		// 	return;
		// }
		const formData = new FormData();
		formData.append("file", file);

		try {
			const response = await axios.post("https://api.tomorrowlandclub.com/events/upload?file", formData, {
				onUploadProgress: (progressEvent) => {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					);
					onProgress({ percent: percentCompleted });
				},
				headers: {
					Authorization: 'Bearer ' + token,
					// 'Content-Type': 'application/json',
					'Content-Type': 'multipart/form-data',
					'Access-Control-Allow-Origin': '*',
				},
			}
			);

			if (response.status === 401 || response.status === 404 || !token) {
				// Redirect to the login page for 401 or 404 errors or if the token is not present
				navigate('/AdminLogin');
				return; // Exit early to prevent further logic execution
			}
			console.log(response.data.uploadedUrl)
			setEventbanner(response.data.uploadedUrl);
			onSuccess("File uploaded");
		} catch (error) {
			onError("File upload failed");
		}
	};

	const handleFileUploadforMultipleImages = async (options) => {
		const { onSuccess, onError, file, onProgress } = options;
		// const maxFileSize = 1024 * 1024;
		// if (file.size > maxFileSize) {
		// 	notification.error({
		// 		message: 'Please upload an image with a size less than 1MB.',
		// 	});
		// 	return;
		// }
		const formData = new FormData();
		formData.append("file", file);

		try {
			const response = await axios.post(
				"https://api.tomorrowlandclub.com/events/uploadgallery?file",
				formData,
				{
					onUploadProgress: (progressEvent) => {
						const percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						onProgress({ percent: percentCompleted });
					},
					headers: {
						// Authorization: 'Bearer ' + token,
						// 'Content-Type': 'application/json',
						'Content-Type': 'multipart/form-data',
						'Access-Control-Allow-Origin': '*',
					},
				}
			);

			if (response.status === 401 || response.status === 404 || !token) {
				navigate('/AdminLogin');
				return;
			}
			const organizerId = response.data.fileid;

			console.log(organizerId)
			setUploadfiles((prevUploadFiles) => [...prevUploadFiles, response.data.fileid]);
			onSuccess("File uploaded");
		} catch (error) {
			onError("File upload failed");
		}
	};

	// Example function to display uploaded files
	const renderUploadedFiles = () => {
		return uploadFiles.map((fileId) => (
			<div key={fileId}>
				<img src={`https://api.tomorrowlandclub.com/events/gallery/${fileId}`} alt={`Uploaded ${fileId}`} />
			</div>
		));
	};

	return (
		<div class="wrapper">

			{/* <!-- LEFT MAIN SIDEBAR --> */}
			<SideBar />

			{/* <!-- PAGE WRAPPER --> */}
			<div class="ec-page-wrapper">

				{/* <!-- Header --> */}
				<Header />

				{/* <!-- CONTENT WRAPPER --> */}
				<div class="ec-content-wrapper">
					<div class="content" style={{ marginTop: "50px" }}>
						<div class="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Events</h1>
							<p class="breadcrumbs"><span><a href="index">Home</a></span>
								<span><i class="mdi mdi-chevron-right"></i></span>Events</p>
						</div>
						<div class="row">
							<div class="col-xl-4 col-lg-12">
								<div class="ec-cat-list card card-default mb-24px">
									<div class="card-body">
										<div class="ec-cat-form">
											<h4>Add Events</h4>

											<form
												onSubmit={onFinish}
											>


												<div className="col-md-12">
													<label className="form-label" name="fileid">Upload Images</label>
													<Form.Item
														name="fileid"
													>
														<Upload
															customRequest={handleFileUpload}
															fileList={fileList}
															onChange={({ fileList }) => setFileList(fileList)}
														>
															<Button icon={<UploadOutlined />} style={{ top: '0px', borderRadius: '0px' }}>Click to Upload</Button>
														</Upload>
													</Form.Item>
												</div>
												<div class="form-group row">
													<label for="slug" class="col-12 col-form-label">Title</label>
													<div class="col-12">
														<input id="slug" name="slug" class="form-control here set-slug" type="text" value={title} onChange={(e) => setTitle(e.target.value)} autoComplete="off" />
													</div>
												</div>

												<div class="form-group row">
													<label class="col-12 col-form-label">Description</label>
													<div class="col-12">
														<CKEditor
															editor={ClassicEditor}
															data={description}
															onChange={(event, editor) => {
																const newSortDescription = editor.getData();
																setDescription(newSortDescription);
															}}
														/>
													</div>
												</div>



												{/* <div class="form-group row">
												<label class="col-12 col-form-label">Event Link</label> 
												<div class="col-12">
													<input id="slug" name="slug" class="form-control here set-slug" type="text"value={eventlink} onChange={(e) => setEventlink(e.target.value)} autoComplete="off" />
												</div>
											</div>  */}

												<div class="form-group row">
													<label class="col-12 col-form-label">Date</label>
													<div class="col-12">
														<DatePicker
															onChange={(date) => setDate(date)}
															value={date}
															format="YYYY-MM-DD"
															placeholder="Select Date"
														/>
													</div>
												</div>
												<div className="col-md-12">
													<label className="form-label" name="fileid">Multiple Images Upload</label>
													<Form.Item
														name="fileid"
													>
														<Upload
															customRequest={handleFileUploadforMultipleImages}
															//   fileList={fileList2}
															//   onChange={({ fileList2}) => setFileList2(fileList2)}
															listType="picture"
														>
															<Button icon={<UploadOutlined />} style={{ top: '0px', borderRadius: '0px' }}>
																Click to Upload
															</Button>
														</Upload>
														<div className="uploaded-files">
															{renderUploadedFiles()}
														</div>
													</Form.Item>
												</div>

												<div class="row">
													<div class="col-12">
														<button name="submit" type="submit" class="btn btn-primary">Submit</button>
													</div>
												</div>

											</form>

										</div>
									</div>
								</div>
							</div>
							<div class="col-xl-8 col-lg-12">
								<div class="ec-cat-list card card-default">
									<div class="card-body">
										<div class="table-responsive">
											<table id="responsive-data-table" class="table">
												<thead>
													<tr>
														<th>Title</th>
														<th>Description</th>
														{/* <th>Event Link</th> */}
														<th>Date</th>
														<th>Event Image</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{data.map((category) => (
														<tr key={category.categoryid}>
															<td dangerouslySetInnerHTML={{ __html: category.eventName }}></td>
															{/* <td><CKEditor
          editor={ClassicEditor}
          data={category.description}
          config={{
            toolbar: [], // Hide the toolbar
            readOnly: true // Set editor to read-only mode
          }}
          onReady={editor => {
            // Optionally: do something when the editor is ready
            console.log('Editor is ready to use!', editor);
          }}
        /></td> */}
															{/* <td>{category.bannerlink}</td> */}
															<td dangerouslySetInnerHTML={{ __html: category.description }}></td>
															<td>{category.date}</td>
															<td>
																{category.eventImage && (
																	<img
																		src={category.eventImage}
																		alt="EventImage"
																		width="100"
																		height="100"
																	/>
																)}
															</td>
															<td>
																<div className="btn-group">
																	<a
																		href="#"
																		className="btn btn-outline-success"
																		onClick={(e) => {
																			e.preventDefault(); // Prevent the anchor tag from navigating
																			handleEditCategory(category);
																		}}
																	>
																		Edit
																	</a>
																	<a
																		href="#"
																		className="btn btn-outline-danger"
																		onClick={(e) => {
																			e.preventDefault(); // Prevent the anchor tag from navigating
																			handleDeleteCategory(category.eventId);
																		}}
																	>
																		Delete
																	</a>
																</div>
															</td>
														</tr>
													))}
												</tbody>

											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				{selectedCategory && (
					<EditSubCategoryModal
						show={showEditModal}
						onHide={handleCloseEditModal}
						onSave={handleSaveEdit}
						categoryList={categoryList}
						category={selectedCategory}
						handleFileUpload={handleFileUpload}
						setreloadList={setreloadListnow}
					/>
				)}
			</div>

		</div>

	);
}
export default Events;