import React, { useState, useEffect } from 'react';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import axios from "axios";

const Booking = () => {
  const navigate = useNavigate();
  const [categoryList, setCategoriesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');

  const handleDeny = async (registrationId) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to deny this registration?");
      if (!confirmDelete) return;
      
      setLoading(true);
      const response = await axios.post(
        'https://api.tomorrowlandclub.com/registrations/confirmation',
        { id: registrationId, confirm: false },
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        console.log('Registration denied successfully');
        fetchCategoryNames();
      } else {
        console.error('Failed to deny registration');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error denying registration:', error);
      setLoading(false);
    }
  };

  const handleConfirm = async (registrationId) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.tomorrowlandclub.com/registrations/confirmation",
        { id: registrationId, confirm: true },
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        console.log('Registration confirmed successfully');
        fetchCategoryNames();
      } else {
        console.error('Failed to confirm registration');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error confirming registration:', error);
      setLoading(false);
    }
  };

  const fetchCategoryNames = async () => {
    try {
      const response = await axios.get("https://api.tomorrowlandclub.com/registrations/fetchall", {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });

      if (response.status === 401 || response.status === 404) {
        navigate('/AdminLogin');
        return;
      }

      // Assuming response.data is an array of registration objects
      const registrations = response.data;

      // Extract inf_id from each registration
      const updatedRegistrations = registrations.map((registration) => ({
        ...registration,
        inf_id: registration.inf_id // Assuming each registration has inf_id
      }));

      setCategoriesList(updatedRegistrations);
    } catch (error) {
      console.error("Error fetching registrations:", error);
    }
  };

  useEffect(() => {
    fetchCategoryNames();
  }, []);

  return (
    <div className="wrapper">
      <SideBar />
      <div className='ec-page-wrapper'>
        <Header />
        <div className="ec-content-wrapper">
          <div className="content" style={{ marginTop: "50px" }}>
            <div className="col-xl-12 col-lg-12">
              <div className="ec-cat-list card card-default">
                <div className="card-body">
                  <div className="table-responsive">
                    <table id="responsive-data-table" className="table">
                      <thead>
                        <tr>
                          <th>Registration Name</th>
                          <th>Mobile Number</th>
                          <th>No. of Members</th>
                          <th>Event Date</th>
                          <th>Influencer ID</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryList.map((category) => (
                          <tr key={category.registration.registrationId}>
                            <td>{category.registration.registrationName}</td>
                            <td>{category.registration.mobileNumber}</td>
                            <td>{category.registration.noOfMembers}</td>
                            <td>{category.event && category.event.date}</td>
                            <td>{category.registration.inf_id}</td>
                            <td>
                              {category.registration.confirmed !== null ? (
                                category.registration.confirmed ? 'Confirmed' : 'Denied'
                              ) : (
                                <div className="btn-group">
                                  <Button
                                    type="button"
                                    className="btn btn-outline-success"
                                    onClick={() => handleConfirm(category.registration.registrationId)}
                                  >
                                    Confirm
                                  </Button>
                                  <Button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() => handleDeny(category.registration.registrationId)}
                                  >
                                    Deny
                                  </Button>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
