import React, { useState, useEffect } from 'react';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Input, Form, Upload, Button, DatePicker, notification } from 'antd';
import axios from "axios";
import { UploadOutlined } from '@ant-design/icons';
import EditSubCategoryModal from './EditSubCategory';
import moment from 'moment';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const qs = require('qs');

const Employee = () => {

	const token = localStorage.getItem('token');
	const [reloadList, setreloadList] = useState(false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [fileid, setUploadfiles] = useState([]);
	const [name, setName] = useState('');
	const [infName, setInfName] = useState('');
	const [userName, setUserName] = useState('');
	const [sex, setSex] = useState('');
	const [mobile, setMobile] = useState('');
	const [date, setDate] = useState('');
	const [categoryList, setCategoriesList] = useState([]);
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);

	const setreloadListnow = () => {
		setreloadList(true);
	};

	const handleEditCategory = (category) => {
		function getAllFileIds(category) {
			if (category && category.files && Array.isArray(category.files)) {
				return category.files.map(file => file.fileid);
			} else {
				console.error("Invalid category object or missing 'files' property.");
				return [];
			}
		}
		let fileIds = getAllFileIds(category);
		sessionStorage.setItem('fileids', JSON.stringify(fileIds));
		setSelectedCategory(category);
		setShowEditModal(true);
	};

	const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	};

	const handleDeleteCategory = async (c_id) => {
		try {
			const confirmDelete = window.confirm("Are you sure you want to delete this customer?");

			if (!confirmDelete) {
				return;
			}
			setLoading(true);
			const response = await axios.delete(
				`https://api.tomorrowlandclub.com/customers/delcusbyid?cid=${c_id}`,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			);
			if (response.status === 200) {
				console.log('Category deleted successfully');
				setreloadList(true);
			} else {
				console.error('Failed to delete category');
			}

			setLoading(false);
		} catch (error) {
			console.error('Error deleting category:', error);
			setLoading(false);
		}
	};

	const handleSaveEdit = async (editedData) => {
		try {
			setLoading(true);
			editedData.c_id = selectedCategory.c_id;
			delete editedData.files;
			const response = await axios.put(
				"https://api.tomorrowlandclub.com/customers/updatecus",
				editedData,
				{
					headers: {
						Authorization: 'Bearer ' + token,
						'Content-Type': 'application/x-www-form-urlencoded',
						'Access-Control-Allow-Origin': '*',
					},
				}
			);

			if (response.status === 401 || response.status === 404) {
				navigate('/AdminLogin');
				return;
			}

			if (response.status === 200) {
				console.log('Subcategory updated successfully');
				window.location.reload();
			} else {
				console.error('PUT request failed');
			}
		} catch (error) {
			console.error('Error updating data:', error);
		} finally {
			setLoading(false);
			handleCloseEditModal();
		}
	};

	useEffect(() => {
		fetchData();
	}, [reloadList]);

	const fetchData = async () => {
		try {
			const response = await axios.get("https://api.tomorrowlandclub.com/customers/allcus", {}, {

				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			});

			setreloadList(false);
			if (response.status === 401 || response.status === 404) {
				navigate('/AdminLogin');
				return;
			}
			setData(response.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const onFinish = async (e) => {
		e.preventDefault();
		const token = localStorage.getItem('token');
		try {
			setLoading(true);
			const response = await fetch("https://api.tomorrowlandclub.com/customers/addcus", {
				method: "POST",
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				body: JSON.stringify({
					// c_id: 'some_id',
					c_Name: name,
					inf_Name: infName,
					c_Sex: sex,
					mobileNumber: Number(mobile),
				}),
			});

			if (response.status === 401 || response.status === 404) {
				navigate('/login');
				return;
			}

			if (response.ok) {
				fetchData();
				const data = await response.json();
				const token = data.token;
				console.log("POST request successful");
				if (response.data && response.data.length > 0) {
					setData(response.data);
				} else {
					setData([]);
				}
				setLoading(false);
				localStorage.removeItem('organizerIds');
				fetchData();
				if (data.token) {
					localStorage.setItem('token', token);
				}
			} else {
				console.error("POST request failed");
			}
		} catch (error) {
			console.error("Error posting data:", error);
			setLoading(false);
		}
	};

	const handleFileUpload = (file) => {
		// Implement file upload logic here
	};

	return (
		<div className="wrapper">
			<SideBar />
			<div className="ec-page-wrapper">
				<Header />
				<div className="ec-content-wrapper">
					<div className="content" style={{ marginTop: "50px" }}>
						<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Customer</h1>
							<p className="breadcrumbs">
								<span><a href="index">Home</a></span>
								<span><i className="mdi mdi-chevron-right"></i></span>Customer
							</p>
						</div>
						<div className="row">
							<div className="col-xl-4 col-lg-12">
								<div className="ec-cat-list card card-default mb-24px">
									<div className="card-body">
										<div className="ec-cat-form">
											<h4>Add Customer</h4>
											<form onSubmit={onFinish}>
												<div className="form-group row">
													<label htmlFor="slug" className="col-12 col-form-label">Customer Name</label>
													<div className="col-12">
														<input id="slug" name="slug" className="form-control here set-slug" type="text" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="slug" className="col-12 col-form-label">Influencer Name</label>
													<div className="col-12">
														<input id="slug" name="slug" className="form-control here set-slug" type="text" value={infName} onChange={(e) => setInfName(e.target.value)} autoComplete="off" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="slug" className="col-12 col-form-label">User Name</label>
													<div className="col-12">
														<input id="slug" name="slug" className="form-control here set-slug" type="text" value={userName} onChange={(e) => setUserName(e.target.value)} autoComplete="off" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="slug" className="col-12 col-form-label">Sex</label>
													<div className="col-12">
														<input id="slug" name="slug" className="form-control here set-slug" type="text" value={sex} onChange={(e) => setSex(e.target.value)} autoComplete="off" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="slug" className="col-12 col-form-label">Mobile No</label>
													<div className="col-12">
														<input id="slug" name="slug" className="form-control here set-slug" type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} autoComplete="off" />
													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<button name="submit" type="submit" className="btn btn-primary">Submit</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-8 col-lg-12">
								<div className="ec-cat-list card card-default">
									<div className="card-body">
										<div className="table-responsive">
											<table id="responsive-data-table" className="table">
												<thead>
													<tr>
														<th>Customer ID</th>
														<th>Customer Name</th>
														<th>Influencer Name</th>
														<th>User Name</th>
														<th>Gender</th>
														<th>Mobile No</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{Array.isArray(data) && data.length > 0 ? (
														data.map((category) => (
															<tr key={category.c_id}>
																<td>{category.c_id}</td>
																<td dangerouslySetInnerHTML={{ __html: category.c_Name }}></td>
																<td dangerouslySetInnerHTML={{ __html: category.inf_Name }}></td>
																<td dangerouslySetInnerHTML={{ __html: category.c_Sex }}></td>
																<td dangerouslySetInnerHTML={{ __html: category.mobileNumber }}></td>
																<td>
																	<div className="btn-group">
																		<a
																			href="#"
																			className="btn btn-outline-success"
																			onClick={(e) => {
																				e.preventDefault();
																				handleEditCategory(category);
																			}}
																		>
																			Edit
																		</a>
																		<a
																			href="#"
																			className="btn btn-outline-danger"
																			onClick={(e) => {
																				e.preventDefault();
																				handleDeleteCategory(category.c_id);
																			}}
																		>
																			Delete
																		</a>
																	</div>
																</td>
															</tr>
														))
													) : (
														<tr>
															<td colSpan="6" style={{ textAlign: 'center' }}>
																No data available
															</td>
														</tr>
													)}
												</tbody>

											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{selectedCategory && (
					<EditSubCategoryModal
						show={showEditModal}
						onHide={handleCloseEditModal}
						onSave={handleSaveEdit}
						categoryList={categoryList}
						category={selectedCategory}
						handleFileUpload={handleFileUpload}
						setreloadList={setreloadListnow}
					/>
				)}
			</div>
		</div>
	);
}
export default Employee;
