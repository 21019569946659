// PlanDurationModal.jsx

import React from 'react';
import { Modal, Button, Form, DatePicker, Select } from 'antd';
const { Option } = Select;

const EditPayment = ({
  visible,
  onCancel,
  onSave,
  expirationdate,
  setDate,
  month,
  setMonth,
  setaddedit
}) => {
    const dateObject = new Date(setaddedit.expirationdate);
    const date = new Date();
    console.log('current'+date)
    console.log('expmodel'+setaddedit.expirationdate)
    console.log('exp'+dateObject)
    // Format the date as yyyy/mm/dd
    const isexpired = dateObject>= date;
    console.log('is true'+isexpired)
  return (
    <Modal
      title="Plan Duration"
      visible={visible}
      onCancel={onCancel}
      footer={!isexpired ? [
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onSave}>
          Save Changes
        </Button>,
      ] : null}
      
    >
         {isexpired ? (
        // If expired, show dateObject in modal
        <div>Paln Expires on : {dateObject.toLocaleDateString('IST')}</div>
      ) : (
      <Form>
        
        <Form.Item label="Select Date">
          <DatePicker onChange={(momentObj) => setDate(momentObj)} value={expirationdate} />
        </Form.Item>
        <Form.Item label="Select Month">
          <Select
            placeholder="Select Month"
            value={month}
            onChange={(value) => setMonth(value)}
          >
            {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
              <Option key={month} value={month < 10 ? `0${month}` : `${month}`}>
                {month < 10 ? `0${month}` : `${month}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      )}
    </Modal>
  );
};

export default EditPayment;
