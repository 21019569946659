import React, { useState, useEffect } from 'react';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import {Select ,Modal,Button} from "antd";
import EditSubSubCategoryModal from './EditSubsubCategory';
import moment from 'moment';
const { Option } = Select;
const DeleteCategoryModal = ({ visible, onCancel, onConfirm }) => {
	return (
	  <Modal
		visible={visible}
		title="Confirm Deletion"
		onCancel={onCancel}
		footer={[
		  <Button key="cancel" onClick={onCancel}>
			Cancel
		  </Button>,
		  <Button key="delete" type="primary" onClick={onConfirm}>
			Delete
		  </Button>,
		]}
	  >
		<p>Are you sure you want to delete this Subsubcategory?</p>
	  </Modal>
	);
  };

const ListProduct = () => {

	const token = localStorage.getItem('token');
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [categorydata, setcategoryData] = useState([]);
  const [ecdata, setEcData] = useState([]);
	const navigate = useNavigate();
	  const [role, setRole] =useState('');
	  const [startmonth, setstartmonth] =useState('');
	  const [endmonth, setendmonth] =useState('');
	  const [startyear, setstartyear] =useState('');
	  const [endyear, setendyear] =useState('');
	  const [categoryList, setCategoriesList] = useState([]);
	  const [selectedCategoryId, setSelectedCategoryId] = useState('');
	  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('');
	  const [subcategoryList, setSubCategoriesList] = useState([]);
	  const [showDeleteModal, setShowDeleteModal] = useState(false);

	  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
	  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
	  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const months = moment.months();
    const startYear = 2016;
    const years = Array.from({ length: 15 }, (_, index) => startYear  + index);
      const handleEditCategory = (category,subcategory) => {
		setSelectedCategory(category);
		setSelectedSubCategory(subcategory);
		setShowEditModal(true);
	  };

	  useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
    fetchDataforecmember();
	  }, []); // Empty dependency array ensures the effect runs only once

	  const fetchData = async () => {
		try {
		  const response = await axios.post("https://api.tomorrowlandclub.com/api/v1/client/get",{},{
			
		  headers: {
			'Authorization': 'Bearer ' +token,
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/login');
			return; // Exit early to prevent further logic execution
		  }
		  setcategoryData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };

    const fetchDataforecmember = async () => {
      try {
        const response = await axios.put("https://api.tomorrowlandclub.com/api/v1/ecmember/get",{},{
        
        headers: {
        'Content-Type': 'application/json',
        },
        });
        if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate('/AdminLogin');
        return; // Exit early to prevent further logic execution
        }
        setEcData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      };
  
	  
	  const handleNameSelect = (clientid) => {
		setSelectedCategoryId(clientid);
		const subCategoryData = categorydata.find(item => item.clientid === clientid);
		// Ensure subCategoryData contains subcategory information
		if (subCategoryData && subCategoryData.subcategory) {
			setSubCategoriesList(subCategoryData.subcategory);
		  } else {
			// If subcategory data is not available, set it to an empty array
			setSubCategoriesList([]);
		  }
	  };
  
    const onFinish = async (e) => {
      e.preventDefault(); // Prevent the default form submission behavior
      const token = localStorage.getItem('token');
      
      try {
        setLoading(true);
        // TODO: Replace with actual API call
        const response = await fetch("https://api.tomorrowlandclub.com/api/v1/ecmember/add", {
        method: "POST",
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientid:selectedCategoryId,
          role,
          startmonth,
          endmonth,
          startyear,
          endyear,
        }),
        });
      
        if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate('/AdminLogin');
        return; // Exit early to prevent further logic execution
        }
      
        if (response.ok) {
        const data = await response.json();
        const token = data.token;
        console.log("POST request successful");
        if (response.data && response.data.length > 0) {
          setData(response.data);
          } else {
          setData([]); // If no data is received, set an empty array
          }
        setLoading(false);
        window.location.reload(); // Refresh the page
        if (data.token) {
          localStorage.setItem('token', token);
        }
        // Optionally, you can update your component state or perform other actions here
        } else {
        console.error("POST request failed");
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setLoading(false);
      }
      };
      const handleDeleteCategory = async (ecmemberid) => {
        try {
          const confirmDelete = window.confirm("Are you sure you want to delete this event?");
    
        if (!confirmDelete) {
          // If the user cancels, do nothing
          return;
        }
          setLoading(true);
        
          // Make an API request to delete the category
          const response = await axios.post(
          `https://api.tomorrowlandclub.com/api/v1/ecmember/delete`,
          {
            ecmemberid: ecmemberid, // Pass the categoryId as a payload
          },
          {
            headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
            },
          }
          );
        
          
        // Check if the deletion was successful
        if (response.status === 200) {
        // The category was deleted successfully
        console.log('Category deleted successfully');
      
        // Reload the page to reflect the changes
        window.location.reload();
        } else {
        console.error('Failed to delete category');
        }
      
        setLoading(false);
        } catch (error) {
          console.error('Error deleting category:', error);
          setLoading(false);
        }
        };
    return(
        <div class="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>

		{/* <!-- PAGE WRAPPER --> */}
		<div class="ec-page-wrapper">

			{/* <!-- Header --> */}
			<Header/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div class="ec-content-wrapper">
				<div class="content" style={{marginTop:"50px"}}>
					<div class="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
						<h1>EC Members</h1>
						<p class="breadcrumbs"><span><a href="index">Home</a></span>
							<span><i class="mdi mdi-chevron-right"></i></span>EC Members</p>
					</div>
					<div class="row">
						<div class="col-xl-4 col-lg-12">
							<div class="ec-cat-list card card-default mb-24px">
								<div class="card-body">
									<div class="ec-cat-form">
										<h4>Assign Members</h4>

										<form
									onSubmit={onFinish}
										>

                                        <div className="form-group row">
                                            <label htmlFor="parent-category" className="col-12 col-form-label">Select Members</label> 
                                            <div className="col-12">
                                            <Select
  style={{ width: '60%' }}
  showSearch
  optionFilterProp="children"
  filterOption={(input, option) => {
    const fullName = `${option.firstname} ${option.lastname}`.toLowerCase();
    return (
      fullName.includes(input.toLowerCase()) ||
      (option.firstname && option.firstname.toLowerCase().includes(input.toLowerCase())) ||
      (option.lastname && option.lastname.toLowerCase().includes(input.toLowerCase())) ||
      (option.companyname && option.companyname.toLowerCase().includes(input.toLowerCase()))
    );
  }}
  onChange={(value) => handleNameSelect(value)}
>
  {categorydata.map((name) => (
    <Select.Option
      key={name.clientid}
      value={name.clientid}
      firstname={name.firstname}
      lastname={name.lastname}
      companyname={name.companyname}
    >
      {name.firstname} {name.lastname} ({name.companyname})
    </Select.Option>
  ))}
</Select>


                                            </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="sub-category" className="col-12 col-form-label">Role</label> 
                                                <div className="col-12">
                                                <Select
                                                style={{ width: '60%' }}
                                                onChange={(value) => setRole(value)}
                                                >
                                                 <Option value="President">President</Option>
          <Option value="Treasuer">Treasuer</Option>
          <Option value="Secretary">Secretary</Option>
          <Option value="ECMember">EC Member</Option><Option value="President">Founding President</Option>
          <Option value="FoundingTreasuer">Founding Treasuer</Option>
          <Option value="FoundingSecretary">Founding Secretary</Option>
          <Option value="FoundingECMember">Founding EC Member</Option>
                                                </Select>
                                                </div>
                                                </div>

<div className="form-group row">
    <label htmlFor="start-month" className="col-12 col-form-label">Start Month</label>
    <div className="col-12">
    <Select style={{ width: '100%' }} onChange={(value) => setstartmonth(value)}>
            {months.map((month, index) => (
              <Select.Option key={index} value={index + 1}>
                {month}
              </Select.Option>
            ))}
          </Select>
    </div>

    <label htmlFor="start-year" className="col-12   col-form-label">Start Year</label>
    <div className="col-12">
    <Select style={{ width: '100%' }} onChange={(value) => setstartyear(value)}>
            {years.map((year) => (
              <Select.Option key={year} value={year}>
                {year}
              </Select.Option>
            ))}
          </Select>
    </div>
</div>

<div className="form-group row">
    <label htmlFor="end-month" className="col-12 col-form-label">End Month</label>
    <div className="col-12">
    <Select style={{ width: '100%' }} onChange={(value) => setendmonth(value)}>
            {months.map((month, index) => (
              <Select.Option key={index} value={index + 1}>
                {month}
              </Select.Option>
            ))}
          </Select>
    </div>

    <label htmlFor="end-year" className="col-12 col-form-label">End Year</label>
    <div className="col-12">
    <Select style={{ width: '100%' }} onChange={(value) => setendyear(value)}>
            {years.map((year) => (
              <Select.Option key={year} value={year}>
                {year}
              </Select.Option>
            ))}
          </Select>
    </div>
</div>



											<div class="row">
												<div class="col-12">
													<button name="submit" type="submit" class="btn btn-primary">Submit</button>
												</div>
											</div>

										</form>

									</div>
								</div>
							</div>
						</div>
            <div class="col-xl-8 col-lg-12">
							<div class="ec-cat-list card card-default">
								<div class="card-body">
									<div class="table-responsive">
										<table id="responsive-data-table" class="table">
											<thead>
												<tr>
													<th>Member</th>
                                                    <th>Role</th>
                                                    <th>Duration</th>
                                                    <th>Action</th>
												</tr>
											</thead>
											<tbody>
                        {ecdata.map((category) => (
                          <tr key={category.clientid}>
						  <td>{category.clientdetail.firstname}</td>
                            <td>{category.role}</td>
                            <td>{category.startmonth}/{category.startyear}-{category.endmonth}/{category.endyear}</td>
                          <td>
							<div className="btn-group"> 
    <a
      href="#"
      className="btn btn-outline-danger"
      onClick={(e) => {
        e.preventDefault(); // Prevent the anchor tag from navigating
        handleDeleteCategory(category.ecmemberid);
      }}
    >
      Delete
    </a> 
  </div>                </td> 
                          </tr>
                        ))}
                      </tbody>
										</table>
									</div>
								</div>
							</div>
					</div>
				</div> 
			</div> 

</div>

		</div> 

	</div> 
  
    );
}
export default ListProduct;